import { Grid, Stack, Typography } from "@mui/material";
import { PatternFormat } from "react-number-format";

function BusinessDetails(props: any) {
  return (
    <div style={{ marginTop: 5 }}>
      <Typography variant="subtitle1" fontWeight="bold">
        Business
      </Typography>
      <Grid container p={1}>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Business Name:</Typography>
            <Typography variant="subtitle2">
              {props.businessData?.name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Website:</Typography>
            <Typography variant="subtitle2">
              {props.businessData?.url}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Business EIN:</Typography>
            <Typography variant="subtitle2">
              <PatternFormat
                value={props.businessData?.ein?.slice(-4)}
                displayType="text"
                type="text"
                format="** **** ####"
              />
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Phone:</Typography>
            <Typography variant="subtitle2">
              (+1){" "}
              <PatternFormat
                value={props.businessData?.phone}
                displayType="text"
                type="text"
                format="### ### ####"
              />
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Doing Business As:</Typography>
            <Typography variant="subtitle2">
              {props.businessData?.nature}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Country:</Typography>
            <Typography variant="subtitle2">
              {props.businessData?.country}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Address 1:</Typography>
            <Typography variant="subtitle2">
              {props.businessData?.address1}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Address 2:</Typography>
            <Typography variant="subtitle2">
              {props.businessData?.address2 || "--"}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">City:</Typography>
            <Typography variant="subtitle2">
              {props.businessData?.city}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">State:</Typography>
            <Typography variant="subtitle2">
              {props.businessData?.state}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Zip Code:</Typography>
            <Typography variant="subtitle2">
              {props.businessData?.zip}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}

export default BusinessDetails;
