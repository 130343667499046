import axios from "axios";
import { toast } from "react-toastify";


const instance = axios.create({
  baseURL: process.env.REACT_APP_ITC_BASE_API_URL,
  headers: {
    post: {
      "Content-Type": "application/json",
    },
  },
});

instance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    } else {
      toast.error("Auth token not found!");
      window.location.href = '/signin';
    }
    return request;
  },
  (error) => {
    toast.error(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if(parseInt(error?.response?.status) === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("role")
      localStorage.removeItem("business_uuid")
      localStorage.removeItem("onboarding_status")
      localStorage.removeItem("link_token")  
      window.location.href = '/signin' 
    }
    toast.error(error);
    return Promise.reject(error);
  }
);

export default instance;
