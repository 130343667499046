import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { AppBar, Grid, List, ListItem, Stack, Toolbar } from "@mui/material";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import moment from "moment";
import { pointer } from "../../styles";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function ViewPaymentModal({ user }: any) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <VisibilityIcon
        color="success"
        sx={{ mr: 1 }}
        style={pointer}
        fontSize="small"
        onClick={handleClickOpen}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        fullWidth
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
              View Fund
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <>
            <Grid item xs={12}>
              <List sx={{ py: 0 }}>
                {/* <ListItem>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography variant="subtitle2">Full Name: </Typography>
                        <Typography variant="subtitle2">
                          {user?.name}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography variant="subtitle2">Role:</Typography>
                        <Typography variant="subtitle2">
                          {user?.role}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem> */}
                <ListItem>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography variant="subtitle2">Status:</Typography>
                        <Typography variant="subtitle2">
                          {user.status}
                        </Typography>
                      </Stack>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography variant="subtitle2">Email:</Typography>
                        <Typography variant="subtitle2">
                          {user?.email}
                        </Typography>
                      </Stack>
                    </Grid> */}
                  </Grid>
                </ListItem>

                <ListItem>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography variant="subtitle2">Created At:</Typography>
                        <Typography variant="subtitle2">
                          {moment(user?.created_at).format(
                            "YYYY-MM-DD hh:mm A z"
                          ) +
                            "(" +
                            moment.tz.guess() +
                            " Time)"}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
