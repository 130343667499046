import React from 'react';
import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { AuthContext } from "./AuthProvider";
import { useLocation } from 'react-router-dom';
import AutoLogoutModal from '../components/AutoLogoutModal';

const timeout = process.env.REACT_APP_IDLE_TIMER ? parseInt(process.env.REACT_APP_IDLE_TIMER) : 300000
const promptBeforeIdle = timeout / 5
const throttle = 6000;

export default function IdleAppChecker() {

    const location = useLocation();

    const { logout }: any = React.useContext(AuthContext);
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = useState<number>(0)
    const [remaining, setRemaining] = useState<number>(0);

    const onIdle = () => {
        logout()
        setOpen(false)
    }

    const onActive = () => {
    }

    const onAction = () => {
        setCount(count + 1)
    }

    const onPrompt = () => {
        if (location.pathname !== "/signin") {
            setOpen(true)
        }
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle
    })

    const onCancel = () => {
        activate()
        setOpen(false);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    return (
        <>
            {/* <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you still there?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your session is about to expire due to inactivity.
                        To ensure the security of your account, you will be automatically logged out in {remaining} {secondsTxt}.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button variant="contained" onClick={() => logout()}>
                        Logout
                    </Button>
                </DialogActions>
            </Dialog> */}
            <AutoLogoutModal
                 open={open}
                 remaining={remaining}
                 onLogout={onIdle}
                 onCancel={onCancel}
             />
        </>

    );
}