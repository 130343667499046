import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import _ from "lodash";

ChartJS.register(ArcElement, Tooltip, Legend);

export function EmptyStatusChart(props: any) {
  const options: any = {
    plugins: {
      tooltip: {
        enabled: false
      },
      title: {
        display: true,
        text: props.text
      },
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 13
        },
      },
    }
  };

  const data = {
    labels: [ "No data"],
    datasets: [
      {
        data: [1],
        backgroundColor: [
          "white",
        ],
        borderColor: [
          "silver",
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut data={data} options={options}/>;
}
