import { SyntheticEvent, useState } from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";

import {
  isLowercaseChar,
  isNumber,
  isSpecialChar,
  isUppercaseChar,
  minLength,
} from "../../utils/PasswordValidation";

import { Formik } from "formik";
import * as Yup from "yup";

import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LineOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import instance from "../../services/AxiosInterceptor";

const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: SyntheticEvent) => {
    event.preventDefault();
  };

  return (
    <>
      <Container component="main" sx={{ p: { xs: 0, md: 'auto' } }}>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card sx={{ width: { xs: '100%', md: "50%" } }}>
            <CardHeader
              title="Change Password"
              titleTypographyProps={{
                textAlign: "center",
                padding: 2,
                fontSize: "20px",
              }}
              subheader="Please provide the needed data to change your password"
              subheaderTypographyProps={{ textAlign: "center" }}
            />

            <CardContent>
              <Formik
                initialValues={{
                  oldPassword: "",
                  password: "",
                  confirm: "",
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  oldPassword: Yup.string().required(
                    "Old password is required"
                  ),
                  password: Yup.string()
                    .required("Password is required")
                    .matches(
                      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
                    ),
                  confirm: Yup.string()
                    .required("Confirm Password is required")
                    .test(
                      "confirm",
                      `Passwords don't match.`,
                      (confirm: string, yup: any) =>
                        yup.parent.password === confirm
                    ),
                })}
                onSubmit={async (
                  values,
                  { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    instance
                      .put("/users/reset-password", {
                        old_password: values.oldPassword,
                        password: values.password,
                      })
                      .then((response) => {
                        toast.success("Password reset successfully!");
                      })
                      .catch((error) => {
                        toast.error(error.message);
                      });

                    resetForm();
                    setStatus({ success: false });
                    setSubmitting(false);
                  } catch (err: any) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Grid item py={1.5}>
                      <Stack spacing={0.5}>
                        <InputLabel htmlFor="old-password">
                          Old Password
                        </InputLabel>
                        <OutlinedInput
                          autoComplete="off"
                          size="small"
                          placeholder="Old Password"
                          id="old-password"
                          type={showOldPassword ? "text" : "password"}
                          value={values.oldPassword}
                          name="oldPassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="small"
                              >
                                {showOldPassword ? (
                                  <EyeOutlined />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          inputProps={{}}
                        />
                        {touched.oldPassword && errors.oldPassword && (
                          <FormHelperText error id="old-password-helper">
                            {errors.oldPassword}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>

                    <Grid item py={1.5}>
                      <Stack spacing={0.5}>
                        <InputLabel htmlFor="password-password">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          size="small"
                          placeholder="Enter Password"
                          id="password-password"
                          type={showNewPassword ? "text" : "password"}
                          value={values.password}
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="small"
                              >
                                {showNewPassword ? (
                                  <EyeOutlined />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          inputProps={{}}
                        />
                        {touched.password && errors.password && (
                          <FormHelperText error id="password-password-helper">
                            {errors.password}
                          </FormHelperText>
                        )}

                        <Box sx={{ pt: 2 }}>
                          <Typography variant="body2">
                            Password Requirements
                          </Typography>
                          <List sx={{ p: 0, mt: 1 }} dense>
                            <ListItem>
                              <ListItemIcon
                                sx={{
                                  color: minLength(values.password)
                                    ? "success.main"
                                    : "inherit",
                                }}
                              >
                                {minLength(values.password) ? (
                                  <CheckCircleOutlineIcon />
                                ) : (
                                  <LineOutlined />
                                )}
                              </ListItemIcon>
                              <ListItemText primary="At least 8 characters" />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon
                                sx={{
                                  color: isLowercaseChar(values.password)
                                    ? "success.main"
                                    : "inherit",
                                }}
                              >
                                {isLowercaseChar(values.password) ? (
                                  <CheckCircleOutlineIcon />
                                ) : (
                                  <LineOutlined />
                                )}
                              </ListItemIcon>
                              <ListItemText primary="At least 1 lower letter (a-z)" />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon
                                sx={{
                                  color: isUppercaseChar(values.password)
                                    ? "success.main"
                                    : "inherit",
                                }}
                              >
                                {isUppercaseChar(values.password) ? (
                                  <CheckCircleOutlineIcon />
                                ) : (
                                  <LineOutlined />
                                )}
                              </ListItemIcon>
                              <ListItemText primary="At least 1 uppercase letter (A-Z)" />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon
                                sx={{
                                  color: isNumber(values.password)
                                    ? "success.main"
                                    : "inherit",
                                }}
                              >
                                {isNumber(values.password) ? (
                                  <CheckCircleOutlineIcon />
                                ) : (
                                  <LineOutlined />
                                )}
                              </ListItemIcon>
                              <ListItemText primary="At least 1 number (0-9)" />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon
                                sx={{
                                  color: isSpecialChar(values.password)
                                    ? "success.main"
                                    : "inherit",
                                }}
                              >
                                {isSpecialChar(values.password) ? (
                                  <CheckCircleOutlineIcon />
                                ) : (
                                  <LineOutlined />
                                )}
                              </ListItemIcon>
                              <ListItemText primary="At least 1 special characters" />
                            </ListItem>
                          </List>
                        </Box>
                      </Stack>
                    </Grid>

                    <Grid item py={3}>
                      <Stack spacing={0.5}>
                        <InputLabel htmlFor="password-confirm">
                          Confirm Password
                        </InputLabel>
                        <OutlinedInput
                          autoComplete="off"
                          size="small"
                          placeholder="Confirm Password"
                          id="password-confirm"
                          type={showConfirmPassword ? "text" : "password"}
                          value={values.confirm}
                          name="confirm"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="small"
                              >
                                {showConfirmPassword ? (
                                  <EyeOutlined />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          inputProps={{}}
                        />
                        {touched.confirm && errors.confirm && (
                          <FormHelperText error id="password-confirm-helper">
                            {errors.confirm}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                      >
                        <Button
                          disabled={
                            isSubmitting || Object.keys(errors).length !== 0
                          }
                          type="submit"
                          variant="contained"
                          size="small"
                        >
                          submit
                        </Button>
                      </Stack>
                    </Grid>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default ChangePassword;
