import { Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import instance from "../../services/AxiosInterceptor";
import BackDrop from "../BackDrop";
import { navy } from "../menu/Drawer";
import EmptyTable from "../onboard/owner/EmptyTable";
import SearchTransaction from "./SearchTransaction";
import TransactionItem from "./TransactionItem";

export const formatCentToUsd = (cent: any) => {
  const usd = (cent / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  return usd;
};

export default function Transaction() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [searchBy, setSearchBy] = React.useState("name");

  const setSearchCriteria = (search: any, searchBy: any) => {
    setSearch(search);
    setSearchBy(searchBy);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  type Order = "asc" | "desc";
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [totalElements, setTotalElements] = React.useState(0);
  const [transactions, setTransactions] = React.useState([]);

  React.useEffect(() => {
    instance
      .get("/transactions", {
        params: {
          page: page,
          size: rowsPerPage,
          order: order,
          sort: orderBy,
          [searchBy]: search,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        setTransactions(data.content);
        setTotalElements(data.totalElements);
      })
      .catch(() => { })
      .finally(() => {
        setLoading(false)
      });
  }, [page, rowsPerPage, order, orderBy, search, searchBy]);

  return (
    <>
      <BackDrop open={loading} />
      <Grid container py={10}>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%" }}>
            <Typography
              fontWeight={"bold"}
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
              px={2}
              py={0.75}
              border={"solid 0.5px silver"}
              bgcolor={"rgba(27,59,101, .1)"}
              color={navy}
            >
              Transactions
            </Typography>
            <Divider />

            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" colSpan={10} size="small">
                      <SearchTransaction setSearchCriteria={setSearchCriteria} />
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell',  p: { xs: '6px', sm: '6px 16px' }  } }} size="small">
                      <Typography style={{ fontWeight: "bold" }}>
                        Date
                        <Typography variant="caption">
                          {" (" + new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] + ")"}
                        </Typography>
                      </Typography>
                    </TableCell >
                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} style={{ fontWeight: "bold" }} size="small">
                      Merchant Category
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", p: { xs: '6px', sm: '6px 16px' } }} size="small">Amount</TableCell>
                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} style={{ fontWeight: "bold" }} size="small">Status</TableCell>
                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} style={{ fontWeight: "bold" }} size="small">
                      Card Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", p: { xs: '6px', sm: '6px 16px' } }} size="small">
                      Card Holder
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "bold", p: { xs: '6px', sm: '6px 16px' } }} size="small">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {transactions.length === 0 ? <EmptyTable /> : transactions?.map((row: any, index) => (
                      <TransactionItem row={row} key={index} />
                    ))}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Grid>
    </>

  );
}
