import React, { useEffect } from "react";
import { useCallback, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "@mui/material";
import instance from "../../services/AxiosInterceptor";
import { useNavigate } from "react-router-dom";

function Bank() {

  const [token, setToken] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const onSuccess = useCallback(async (public_token: any) => {
    setLoading(true);

    instance
      .post("/bank/exchange_public_token", {
        public_token,
      })
      .then((response: any) => response.data)
      .then((data: any) => {
        navigate("/banking");
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const createLinkToken = React.useCallback(async () => {
    if (window.location.href.includes("?oauth_state_id=")) {
      const linkToken: any = localStorage.getItem("link_token");
      setToken(linkToken);
    } else {
      instance
        .get("/bank/create_link_token")
        .then((response) => response.data)
        .then((data) => {
          setToken(data.link_token);
          localStorage.setItem("link_token", data.link_token);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [setToken]);

  let isOauth = false;

  const config: any = {
    token,
    onSuccess,
  };

  // For OAuth, configure the received redirect URI
  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (token == null) {
      createLinkToken();
    }
    if (isOauth && ready) {
      open();
    }
  }, [token, isOauth, ready, open]);

  return (
    <div>
      <Button
        onClick={() => open()}
        // disabled={!ready}
        disabled={!token}
        variant="contained"
        startIcon={<PlusOutlined />}
        size="small"
        sx={{width: {xs: '100%', sm: 120}}}
      >
        Add Bank
      </Button>

      {!loading &&
        data != null &&
        Object.entries(data).map((entry, i) => (
          <pre key={i}>
            <code>{JSON.stringify(entry[1], null, 2)}</code>
          </pre>
        ))}
    </div>
  );
}

export default Bank;
