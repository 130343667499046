import {
  Divider,
  Grid,
  TableSortLabel,
  Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import * as React from "react";
import { toast } from "react-toastify";
import BackDrop from "../components/BackDrop";
import { navy } from "../components/menu/Drawer";
import EmptyTable from "../components/onboard/owner/EmptyTable";
import { formatCentToUsd } from "../components/transaction/Transaction";
import instance from "../services/AxiosInterceptor";



interface Column {
  id:
  | "amount"
  | "descriptor"
  | "state"
  | "city"
  | "country"
  | "card_name"
  | "card_owner"
  | "created_at"
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: "amount",
    label: "Amount",
    minWidth: 25,
  },
  { id: "descriptor", label: "Descriptor", minWidth: 50 },
  { id: "state", label: "State", minWidth: 50 },
  { id: "city", label: "City", minWidth: 100 },
  { id: "country", label: "Country", minWidth: 100 },
  { id: "card_name", label: "Card Name", minWidth: 100 },
  { id: "card_owner", label: "Card Owner", minWidth: 100 },
  { id: "created_at", label: "Created At", minWidth: 100 },
];

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

interface SyncedData {
  sync_uuid: string;
  transaction: {
    descriptor: string;
    amount: string;
    state: string;
    city: string;
    country: string;
    card: {
      name: string;
      user: {
        name: string;
        email: string;
      }
    };
  }

  created_at: string;
}

export default function SyncedList() {
  const [fetchingSyncedItems, setFetchingSyncedItems] = React.useState(true);

  const [totalElements, setTotalElements] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  type Order = "asc" | "desc";
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [search, setSearch] = React.useState("");
  const [searchBy, setSearchBy] = React.useState("name");

  const [syncedItems, setSyncedItems] = React.useState([]);

  const setSearchCriteria = (search: any, searchBy: any) => {
    setSearch(search);
    setSearchBy(searchBy);
  };

  const fetchSyncedItems = () => {
    instance
      .get("/accounting/synced", {
        params: {
          page: page,
          size: rowsPerPage,
          order: order,
          sort: orderBy,
        },
      })
      .then((response: any) => response.data)
      .then((data: any) => {
        //
        const newPage = page;
        setPage(newPage);

        setSyncedItems(data.content);
        setTotalElements(data.totalElements);
      })
      .catch((error: any) => {
        toast.error(error.response);
      })
      .finally(() => {
        setFetchingSyncedItems(false);
      });
  };

  React.useEffect(() => {
    fetchSyncedItems();
  }, [page, rowsPerPage, order, orderBy, search, searchBy]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <BackDrop open={fetchingSyncedItems} />
      <Grid container py={5}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <Typography
            fontWeight={"bold"}
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            px={2}
            py={0.75}
            border={"solid 0.5px silver"}
            bgcolor={"rgba(27,59,101, .1)"}
            color={navy}
          >
            Synced
          </Typography>
          <Divider />
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      size="small"
                      sortDirection={false}
                      key={column.id}
                      align={column.label === 'Action' ? 'right' : column.align}
                      sx={{
                        minWidth: column.minWidth,
                        fontVariant: "h4",
                        fontWeight: "bold",
                        display: { xs: column.label == 'Descriptor' || column.label == 'State' || column.label == 'Created At' || column.label == 'City' || column.label == 'Country' ? 'none' : 'table-cell', sm: 'table-cell' },
                        p: { xs: '6px', sm: '6px 16px' }
                      }}
                    >
                      {column.label}
                      {(
                        <TableSortLabel
                          sx={{
                            "& .MuiTableSortLabel-icon": {
                              color: "black !important",
                            },
                          }}
                          active={column.id === orderBy}
                          direction={order}
                          onClick={(event) => {
                            if (column.id === orderBy) {
                              order === "asc"
                                ? setOrder("desc")
                                : setOrder("asc");
                            }
                            setOrderBy(column.id);
                          }}
                        ></TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {syncedItems.length === 0 ? <EmptyTable /> : syncedItems?.map((row: SyncedData) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.sync_uuid}
                    >
                      <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} className="text-overflow-table" key="amount" size="small">
                        <Typography variant="body2">{formatCentToUsd(row.transaction.amount)}</Typography>
                      </TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px'} }} key="descriptor" size="small" >
                        <Typography variant="body2">
                          {row.transaction.descriptor}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px'} }} key="state" size="small">
                        <Typography variant="body2">
                          {row.transaction.state}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px'} }} key="city" size="small">
                        <Typography variant="body2">
                          {row.transaction.city}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px'} }} key="country" size="small">
                        <Typography variant="body2">
                          {row.transaction.country}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }}  key="card_name" size="small">
                        <Typography className="text-overflow-table" sx={{maxWidth: '100px'}} variant="body2">
                          {row.transaction.card?.name}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} key="card_owner" size="small">
                        <Typography variant="body2">
                          <>
                            <Typography className="text-overflow-table" sx={{maxWidth: '100px'}} variant="body2">{row.transaction.card?.user.email}</Typography>
                            <Typography className="text-overflow-table" sx={{maxWidth: '100px'}} variant="caption">
                              {row.transaction.card?.user.name}
                            </Typography>
                          </>
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px'} }} key="created_at" size="small">
                        <Typography variant="subtitle2">
                          {moment(row.created_at).format("YYYY-MM-DD HH:mm z")}
                        </Typography>
                      </TableCell>
                      {/* <TableCell size="small" key={"action"}>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </>
  );
}
