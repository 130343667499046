import CloseIcon from "@mui/icons-material/Close";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as React from "react";
import * as yup from "yup";

import {
  AppBar,
  Avatar,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
} from "@mui/material";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { toast } from "react-toastify";
import { getInstitutionLogo } from "../bank/BankList";
import { NumericFormat } from "react-number-format";
import instance from "../../services/AxiosInterceptor";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function FundModal({ fetchPayments }: any) {

  const [open, setOpen] = React.useState(false);
  const [institutionData, setInstitutionData] = React.useState([]);

  React.useEffect(() => {
    instance
      .get("/bank/institutions")
      .then((response) => {
        setInstitutionData(response.data);
      })
      .then(() => {})
      .catch((error) => {
        toast.error(error.message);
      });
  }, []);

  const handleClickOpen = () => {
    formik.resetForm();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialValues = {
    amount: "",
    account_uuid: "",
  };

  const validationSchema = yup.object({
    account_uuid: yup.string().required(),
    amount: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      instance
        .post("/bank/fund", {
          amount: values.amount.replace(/[$,.]/g, ""),
          account_uuid: values.account_uuid,
        })
        .then((response) => response.data)
        .then((data) => {
          handleClose();
          toast.success("Account funded successfully!");
          fetchPayments();
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    },
  });

  const { errors, touched, values, handleSubmit, handleChange, handleBlur } =
    formik;

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        startIcon={<MonetizationOnIcon />}
        size="small"
        sx={{width: {xs: '100%', sm: 120}}}
      >
        Add Fund
      </Button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
              Add Fund
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent dividers>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit} id="add-user-form">
              <Grid mx={10} mb={5}>
                <FormControl size="small" sx={{ width: 200 }}>
                  <InputLabel
                    id="account-uuid-select-helper-label"
                    variant="standard"
                    error={touched.account_uuid && Boolean(errors.account_uuid)}
                  >
                    Back Account
                  </InputLabel>
                  <Select
                    variant="standard"
                    margin="dense"
                    labelId="account-uuid-select-helper-label"
                    id="account-uuid-select-helper"
                    name="account_uuid"
                    value={values.account_uuid}
                    label="Bank Account"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.account_uuid && Boolean(errors.account_uuid)}
                  >
                    <MenuItem value="">
                      <em>Select bank account</em>
                    </MenuItem>
                    {institutionData.map((institution: any) => {
                      return institution.accounts.map((account: any) => {
                        return (
                          <MenuItem
                            key={account.account_uuid}
                            value={account.account_uuid}
                          >
                            <Avatar
                              alt={institution.institution_name}
                              src={getInstitutionLogo(
                                institution.institution_id
                              )}
                              sx={{ width: 20, height: 20 }}
                            />
                            <Typography variant="body1" pl={1.5}>
                              {account.name}
                            </Typography>
                          </MenuItem>
                        );
                      });
                    })}
                  </Select>
                  {touched.account_uuid && Boolean(errors.account_uuid) && (
                    <FormHelperText error>{errors.account_uuid}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item mx={10} my={3}>
                <NumericFormat
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  allowLeadingZeros
                  thousandSeparator=","
                  customInput={TextField}
                  fullWidth
                  margin="dense"
                  size="small"
                  variant="standard"
                  id="amount"
                  name="amount"
                  label="Amount ($)"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.amount && Boolean(errors.amount)}
                  helperText={touched.amount && errors.amount}
                />
              </Grid>

              <DialogActions>
                <Button autoFocus type="submit" variant="outlined" size="small">
                  Submit
                </Button>
              </DialogActions>
            </Form>
          </FormikProvider>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
