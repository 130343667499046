import { SyntheticEvent, useState } from "react";

// material-ui
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// project import

import {
  isLowercaseChar,
  isNumber,
  isSpecialChar,
  isUppercaseChar,
  minLength,
} from "../../utils/PasswordValidation";

// third party
import { Formik } from "formik";
import * as Yup from "yup";

// assets
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LineOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import instance from "../../services/AxiosInterceptor";
// ==============================|| TAB - PASSWORD CHANGE ||============================== //

const ResetPassword = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: SyntheticEvent) => {
    event.preventDefault();
  };

  const [queryParameters] = useSearchParams();

  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            password: "",
            confirm: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required("Password is required")
              .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one number and one special case character"
              ),
            confirm: Yup.string()
              .required("Confirm Password is required")
              .test(
                "confirm",
                `Passwords don't match.`,
                (confirm: string, yup: any) => yup.parent.password === confirm
              ),
          })}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              instance
                .post(
                  process.env.REACT_APP_ITC_BASE_API_URL +
                    "/users/reset-password",
                  {
                    token: queryParameters.get("token"),
                    password: values.password,
                  }
                )
                .then((response) => {
                  navigate("/signin", { replace: true });
                })
                .catch((error) => {
                  toast.error(error.message);
                });

              resetForm();
              setStatus({ success: false });
              setSubmitting(false);
            } catch (err: any) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid item py={3}>
                <Stack spacing={0.5}>
                  <InputLabel htmlFor="password-password">Password</InputLabel>
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Password"
                    id="password-password"
                    type={showNewPassword ? "text" : "password"}
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="small"
                        >
                          {showNewPassword ? (
                            <EyeOutlined />
                          ) : (
                            <EyeInvisibleOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{}}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText error id="password-password-helper">
                      {errors.password}
                    </FormHelperText>
                  )}

                  <Box sx={{ pt: 2 }}>
                    <Typography variant="body2">
                      Password Requirements
                    </Typography>
                    <List sx={{ p: 0, mt: 1 }} dense>
                      <ListItem>
                        <ListItemIcon
                          sx={{
                            color: minLength(values.password)
                              ? "success.main"
                              : "inherit",
                          }}
                        >
                          {minLength(values.password) ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <LineOutlined />
                          )}
                        </ListItemIcon>
                        <ListItemText primary="At least 8 characters" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon
                          sx={{
                            color: isLowercaseChar(values.password)
                              ? "success.main"
                              : "inherit",
                          }}
                        >
                          {isLowercaseChar(values.password) ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <LineOutlined />
                          )}
                        </ListItemIcon>
                        <ListItemText primary="At least 1 lower letter (a-z)" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon
                          sx={{
                            color: isUppercaseChar(values.password)
                              ? "success.main"
                              : "inherit",
                          }}
                        >
                          {isUppercaseChar(values.password) ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <LineOutlined />
                          )}
                        </ListItemIcon>
                        <ListItemText primary="At least 1 uppercase letter (A-Z)" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon
                          sx={{
                            color: isNumber(values.password)
                              ? "success.main"
                              : "inherit",
                          }}
                        >
                          {isNumber(values.password) ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <LineOutlined />
                          )}
                        </ListItemIcon>
                        <ListItemText primary="At least 1 number (0-9)" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon
                          sx={{
                            color: isSpecialChar(values.password)
                              ? "success.main"
                              : "inherit",
                          }}
                        >
                          {isSpecialChar(values.password) ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <LineOutlined />
                          )}
                        </ListItemIcon>
                        <ListItemText primary="At least 1 special characters" />
                      </ListItem>
                    </List>
                  </Box>
                </Stack>
              </Grid>

              <Grid item py={3}>
                <Stack spacing={0.5}>
                  <InputLabel htmlFor="password-confirm">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    size="small"
                    placeholder="Confirm Password"
                    id="password-confirm"
                    type={showConfirmPassword ? "text" : "password"}
                    value={values.confirm}
                    name="confirm"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="small"
                        >
                          {showConfirmPassword ? (
                            <EyeOutlined />
                          ) : (
                            <EyeInvisibleOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{}}
                  />
                  {touched.confirm && errors.confirm && (
                    <FormHelperText error id="password-confirm-helper">
                      {errors.confirm}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    disabled={isSubmitting || Object.keys(errors).length !== 0}
                    type="submit"
                    variant="contained"
                    size="small"
                  >
                    submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default ResetPassword;
