import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { AppBar, Toolbar } from "@mui/material";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { toast } from "react-toastify";
import { pointer } from "../../styles";
import instance from "../../services/AxiosInterceptor";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function DeleteCardModal(props: any) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleDelete(): void {
    instance
      .delete("/cards?uuid=" + props.card.card_uuid)
      .then((response) => {
        props.fetchCards();
        toast.success("Card deleted successfully.");
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setOpen(false);
      });
  }
  return (
    <>
      <DeleteOutlinedIcon
        color="error"
        onClick={handleClickOpen}
        fontSize="small"
        style={pointer}
      />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              Confirm
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Typography sx={{ p: 3 }}>
            Please confirm to delete the card. This action is irreversible.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={handleDelete}
            sx={{ m: 1.5 }}
          >
            delete
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
