import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";

function BackDrop({ open }: any) {
  return (
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} style={{ backgroundColor: "white" }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress color="primary"
              size={75}
              thickness={1}
            />
          </Grid>
          <Grid item m={5}>
            <Typography
              fontWeight={"bold"}
              color={"primary"}
              letterSpacing={"0.5"}
              >
              Please wait while loading your data...</Typography>
          </Grid>
        </Grid>
      </Backdrop>
  );
}

export default BackDrop;
