import { SyntheticEvent, useState } from "react";

// material-ui
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// project import

import {
  isLowercaseChar,
  isNumber,
  isSpecialChar,
  isUppercaseChar,
  minLength,
} from "../../utils/PasswordValidation";

// third party
import { Formik } from "formik";
import * as Yup from "yup";

import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LineOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const SetPassword = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: SyntheticEvent) => {
    event.preventDefault();
  };

  const [queryParameters] = useSearchParams();

  const navigate = useNavigate();
  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card
          sx={{ width: { xs: '100%', sm: '50%' } }}>
          <CardHeader
            title="Set Password"
            titleTypographyProps={{
              textAlign: "center",
              padding: 2,
              fontSize: "20px",
            }}
            subheader="Please fill out following form to set your password"
            subheaderTypographyProps={{ textAlign: "center" }}
          />

          <CardContent>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                password: "",
                confirm: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required("First name is required"),
                lastName: Yup.string().required("Last name is required"),
                password: Yup.string()
                  .required("Password is required")
                  .matches(
                    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                    "Password must contain at least 8 characters, one uppercase, one number and one special case character"
                  ),
                confirm: Yup.string()
                  .required("Confirm Password is required")
                  .test(
                    "confirm",
                    `Passwords don't match.`,
                    (confirm: string, yup: any) =>
                      yup.parent.password === confirm
                  ),
              })}
              onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  axios
                    .post(
                      process.env.REACT_APP_ITC_BASE_API_URL +
                      "/onboard/set-password",
                      {
                        uuid: queryParameters.get("uuid"),
                        firstName: values.firstName,
                        lastName: values.lastName,
                        password: values.password,
                      }
                    )
                    .then((response) => {
                      toast.success("User details saved successfully!")
                      navigate("/signin", { replace: true });
                    })
                    .catch((error) => {
                      console.error();
                      toast.error(
                        error.response.data.message || error.message
                      );
                    });
                } catch (err: any) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid item py={1} xs={12}>
                    <Stack spacing={0.5}>
                      <TextField
                        size="small"
                        variant="outlined"
                        id="first-name"
                        name="firstName"
                        label="First Name"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        autoComplete="off"
                      />
                    </Stack>
                  </Grid>

                  <Grid item py={1} xs={12}>
                    <Stack spacing={0.5}>
                      <TextField
                        size="small"
                        variant="outlined"
                        id="last-name"
                        name="lastName"
                        label="Last Name"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        autoComplete="off"
                      />
                    </Stack>
                  </Grid>

                  <Grid item py={1} xs={12}>
                    <Stack spacing={0.5}>
                      <InputLabel htmlFor="password-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        autoComplete="off"
                        size="small"
                        placeholder="Enter Password"
                        id="password-password"
                        type={showNewPassword ? "text" : "password"}
                        value={values.password}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="small"
                            >
                              {showNewPassword ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{}}
                      />
                      {touched.password && errors.password && (
                        <FormHelperText error id="password-password-helper">
                          {errors.password}
                        </FormHelperText>
                      )}

                      <Box sx={{ pt: 2 }}>
                        <Typography variant="body2">
                          Password Requirements:
                        </Typography>
                        <List dense>
                          <ListItem style={{}}>
                            <ListItemIcon
                              sx={{
                                margin: 0,
                                padding: 0,
                                border: "solid 2pxx red",
                                color: minLength(values.password)
                                  ? "success.main"
                                  : "inherit",
                              }}
                            >
                              {minLength(values.password) ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                <LineOutlined />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="At least 8 characters" />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon
                              sx={{
                                color: isLowercaseChar(values.password)
                                  ? "success.main"
                                  : "inherit",
                              }}
                            >
                              {isLowercaseChar(values.password) ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                <LineOutlined />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="At least 1 lower letter (a-z)" />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon
                              sx={{
                                color: isUppercaseChar(values.password)
                                  ? "success.main"
                                  : "inherit",
                              }}
                            >
                              {isUppercaseChar(values.password) ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                <LineOutlined />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="At least 1 uppercase letter (A-Z)" />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon
                              sx={{
                                color: isNumber(values.password)
                                  ? "success.main"
                                  : "inherit",
                              }}
                            >
                              {isNumber(values.password) ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                <LineOutlined />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="At least 1 number (0-9)" />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon
                              sx={{
                                color: isSpecialChar(values.password)
                                  ? "success.main"
                                  : "inherit",
                              }}
                            >
                              {isSpecialChar(values.password) ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                <LineOutlined />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="At least 1 special characters" />
                          </ListItem>
                        </List>
                      </Box>
                    </Stack>
                  </Grid>

                  <Grid item py={1}>
                    <Stack spacing={0.5}>
                      <InputLabel htmlFor="password-confirm">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        size="small"
                        placeholder="Confirm Password"
                        id="password-confirm"
                        type={showConfirmPassword ? "text" : "password"}
                        value={values.confirm}
                        name="confirm"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="small"
                            >
                              {showConfirmPassword ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{}}
                      />
                      {touched.confirm && errors.confirm && (
                        <FormHelperText error id="password-confirm-helper">
                          {errors.confirm}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                    >
                      <Button
                        disabled={
                          isSubmitting || Object.keys(errors).length !== 0
                        }
                        type="submit"
                        variant="contained"
                        size="small"
                      >
                        submit
                      </Button>
                    </Stack>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default SetPassword;
