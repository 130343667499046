import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Badge,
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { toast } from "react-toastify";
import instance from "../../services/AxiosInterceptor";
import _ from "lodash";

export default function MccRestrictionTable(props: any) {
  const [loading, setLoading] = React.useState(true);
  const [mccs, setMccs] = React.useState([]);
  const [chosenMccs, setChosenMccs] = React.useState<string[]>([]);
  const [expandedItems, setExpandedItems] = React.useState<string[]>([]);

  React.useEffect(() => {
    fetchMccs();
  }, []);

  const fetchMccs = () => {
    instance
      .get("/mccs")
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setMccs(data);
      })
      .catch((error) => {
        toast.error(error.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateChosenMccs = (id: any, intermediate: boolean) => {
    if (
      chosenMccs.find((element: any) => element === id) === undefined &&
      !intermediate
    ) {
      setChosenMccs((chosenMccs) => [id, ...chosenMccs]);
      props.parentCallback("add", id);
    } else {
      setChosenMccs((current) => current.filter((element) => element !== id));
      props.parentCallback("remove", id);
    }
  };

  const updateExpandedItems = (category: any) => {
    if (
      expandedItems.find((element: any) => element === category) === undefined
    ) {
      setExpandedItems([...expandedItems, category]);
    } else {
      setExpandedItems((current) =>
        current.filter((element) => element !== category)
      );
    }
  };

  const isItemExpanded = (category: any) => {
    return (
      expandedItems.find((element: any) => element === category) !== undefined
    );
  };

  function isIndeterminate(mcc: any) {
    let ids: any = [];
    mcc.mccs.map((item: any) => ids.push(item.id));

    let presents = _.intersectionWith(chosenMccs, ids, _.isEqual);
    if (presents.length > 0 && presents.length < ids.length) {
      return true;
    }
    return false;
  }

  function isChecked(mcc: any): boolean | undefined {
    let ids: any = [];
    mcc.mccs.map((item: any) => ids.push(item.id));
    return _.difference(ids, chosenMccs).length === 0;
  }

  return (
    <>
      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="mcc-restriction-table">
            <TableHead>
              <TableRow>
                <TableCell rowSpan={1}>
                  <Typography variant="body1">
                    Merchant Categories Restrictions
                  </Typography>
                </TableCell>
                <TableCell rowSpan={1}>
                  <Badge
                    badgeContent={chosenMccs.length}
                    color="warning"
                  ></Badge>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mccs?.map((mcc: any, index: number) => (
                <>
                  <TableRow
                    sx={{ "& > *": { border: "unset" } }}
                    key={mcc.category}
                  >
                    <TableCell component="th" scope="row">
                      <FormControlLabel
                        label={mcc.category} // category
                        control={
                          <Checkbox
                            size="small"
                            indeterminate={isIndeterminate(mcc)}
                            checked={isChecked(mcc)}
                            onChange={() => {
                              let ids: any = [];
                              mcc.mccs.map((item: any) => ids.push(item.id));

                              ids.forEach((id: any) => {
                                updateChosenMccs(id, isIndeterminate(mcc));
                              });
                            }}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => updateExpandedItems(mcc.category)}
                      >
                        {isItemExpanded(mcc.category) ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={isItemExpanded(mcc.category)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ margin: 1 }}>
                          <Table size="small" aria-label="purchases">
                            {/* <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead> */}
                            <TableBody>
                              {mcc.mccs.map((mccDetail: any) => (
                                <TableRow key={mccDetail.id}>
                                  <TableCell component="th" scope="row">
                                    <FormControlLabel
                                      label={mccDetail?.description}
                                      control={
                                        <Checkbox
                                          onChange={() => {
                                            updateChosenMccs(
                                              mccDetail.id,
                                              false
                                            );
                                          }}
                                          size="small"
                                          checked={
                                            chosenMccs.find(
                                              (element: any) =>
                                                element === mccDetail.id
                                            ) !== undefined
                                          }
                                        />
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
