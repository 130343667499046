import { EditOutlined } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { OwnerData } from "../../../models/OwnerData";
import { pointer } from "../../../styles";
import DeleteOwnerModal from "./DeleteOwnerModal";
import EmptyTable from "./EmptyTable";
import OwnerDetails from "./OwnerDetails";

function Row(props: {
  row: OwnerData;
  fetchOwner: (uuid: string) => {};
  deleteOwner: (uuid: string) => {};
}) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row" size="small">
          {row.name}
        </TableCell>
        <TableCell size="small">{row.email}</TableCell>
        <TableCell size="small">{row.ownershipPercentage}</TableCell>
        <TableCell size="small">
          <VisibilityIcon
            color="success"
            sx={{ mr: 1 }}
            style={pointer}
            fontSize="small"
            onClick={() => setOpen(!open)}
          />
          <EditOutlined
            sx={{ mr: 1 }}
            style={pointer}
            fontSize="small"
            color="secondary"
            onClick={() => {
              props.fetchOwner(row.ownerUuid);
            }}
          />
          <DeleteOwnerModal
            uuid={row.ownerUuid}
            deleteOwner={() => props.deleteOwner(row.ownerUuid)}
            style={pointer}
            fontSize="small"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} size="small"> 
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow></TableRow>
                </TableHead>
                <TableBody>
                  <OwnerDetails owner={row} />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function OwnersTable(props: any) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }} size="small">Owner</TableCell>
            <TableCell style={{ fontWeight: "bold" }} size="small">Email</TableCell>
            <TableCell style={{ fontWeight: "bold" }} size="small">Ownership %</TableCell>
            <TableCell style={{ fontWeight: "bold" }} size="small">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.owners.length ? (
            props.owners.map((row: any) => (
              <Row
                key={row.name}
                row={row}
                fetchOwner={() => props.fetchOwner(row.ownerUuid)}
                deleteOwner={() => props.deleteOwner(row.ownerUuid)}
              />
            ))
          ) : (
            <EmptyTable message="No owner added yet!" />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
