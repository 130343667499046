import AddCardIcon from "@mui/icons-material/AddCard";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as React from "react";
import { NumericFormat } from "react-number-format";
import * as yup from "yup";

import {
  AppBar,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Toolbar,
} from "@mui/material";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { toast } from "react-toastify";
import instance from "../../services/AxiosInterceptor";
import MccRestrictionTable from "./MccRestrictionTable";
import { ToastError } from "../ToastError";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function AddCardModal({ fetchCards, users }: any) {
  //
  const [open, setOpen] = React.useState(false);
  const [mccIds, setMccIds] = React.useState<string[]>([]);

  const handleClickOpen = () => {
    formik.resetForm();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialValues = {
    name: "",
    description: "",
    type: "",
    userUuid: "",
    spendLimitDuration: "",
    spendLimit: "",
    restrictionFlag: "",
  };

  const validationSchema = yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    type: yup.string().required(),
    userUuid: yup.string().required(),
    spendLimitDuration: yup.string().required(),
    spendLimit: yup.string().required(),
    restrictionFlag: yup.string().required(),
  });

  const submitHandler = (values: any) => {
    instance
      .post("/cards", {
        name: values.name,
        description: values.description,
        type: values.type,
        userUuid: values.userUuid,
        spendLimitDuration: values.spendLimitDuration,
        spendLimit: values.spendLimit.replace(/[$,.]/g, ""),
        merchantRestrictionFlag: values.restrictionFlag,
        mccIds: mccIds,
      })
      .then((response) => response.data)
      .then((data) => {
        handleClose();
        toast.success("Card added successfully!");
        fetchCards();
        setMccIds([]);
      })
      .catch((error: any) => {
        ToastError(error)
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  const { errors, touched, values, handleSubmit, handleChange, handleBlur } =
    formik;

  function handleChosenMccs(operation: any, id: any) {
    if (operation === "add") {
      setMccIds((mccIds) => [id, ...mccIds]);
    } else {
      setMccIds((mccIds) => mccIds.filter((element) => element !== id));
    }
  }

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        startIcon={<AddCardIcon />}
        size="small"
        sx={{width: {xs: '100%', sm: 120}}}
      >
        Add Card
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
              Add Card
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit} id="add-card-form">
              <Grid container spacing={3} justifyContent="center" my={5}>
                <Grid item xs={12} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                  <TextField
                    fullWidth
                    margin="dense"
                    size="small"
                    variant="standard"
                    id="name"
                    name="name"
                    label="Card Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>

                <Grid item xs={12} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                  <TextField
                    fullWidth
                    margin="dense"
                    size="small"
                    variant="standard"
                    id="description"
                    name="description"
                    label="Description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>

                <Grid item xs={12} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                  <FormControl
                    size="small"
                    fullWidth
                    // sx={{ width: 200 }}
                  >
                    <InputLabel
                      id="type-select-helper-label"
                      variant="standard"
                      error={touched.type && Boolean(errors.type)}
                    >
                      Card Type
                    </InputLabel>
                    <Select
                      variant="standard"
                      margin="dense"
                      labelId="type-select-helper-label"
                      id="type-select-helper"
                      name="type"
                      value={values.type}
                      label="Type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.type && Boolean(errors.type)}
                    >
                      <MenuItem value="">
                        <em>Select type</em>
                      </MenuItem>
                      <MenuItem value="VIRTUAL">Virtual</MenuItem>
                      <MenuItem value="PHYSICAL">Physical</MenuItem>
                    </Select>
                    {touched.type && Boolean(errors.type) && (
                      <FormHelperText error>{errors.type}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                  <FormControl
                    size="small"
                    // sx={{ width: 200 }}
                    fullWidth
                  >
                    <InputLabel
                      id="user-select-helper-label"
                      variant="standard"
                      error={touched.userUuid && Boolean(errors.userUuid)}
                    >
                      User
                    </InputLabel>
                    <Select
                      variant="standard"
                      margin="dense"
                      labelId="user-select-helper-label"
                      id="user-select-helper"
                      name="userUuid"
                      value={values.userUuid}
                      label="User"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.userUuid && Boolean(errors.userUuid)}
                    >
                      <MenuItem value="">
                        <em>Select user</em>
                      </MenuItem>
                      {users?.map((user: any) => {
                        return (
                          <MenuItem key={user.user_uuid} value={user.user_uuid}>
                            {user.email}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {touched.userUuid && Boolean(errors.userUuid) && (
                      <FormHelperText error>{errors.userUuid}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                  <Stack spacing={0.5}>
                    <NumericFormat
                      fullWidth
                      // sx={{ width: 200 }}
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      allowLeadingZeros
                      thousandSeparator=","
                      customInput={TextField}
                      margin="dense"
                      size="small"
                      variant="standard"
                      id="spendLimit"
                      name="spendLimit"
                      label="Spend Limit ($)"
                      value={values.spendLimit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.spendLimit && Boolean(errors.spendLimit)}
                      helperText={touched.spendLimit && errors.spendLimit}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                  <Stack>
                    <FormControl
                      size="small"
                      fullWidth
                      // sx={{ width: 200 }}
                      // style={{
                      //   marginTop: "0.5rem",
                      // }}
                    >
                      <InputLabel
                        id="spend-limit-duration-select-helper-label"
                        variant="standard"
                        error={
                          touched.spendLimitDuration &&
                          Boolean(errors.spendLimitDuration)
                        }
                      >
                        Spend Limit Duration
                      </InputLabel>
                      <Select
                        variant="standard"
                        margin="dense"
                        labelId="spend-limit-duration-select-helper-label"
                        id="spend-limit-duration-select-helper"
                        name="spendLimitDuration"
                        value={values.spendLimitDuration}
                        label="Spend Limit Duration"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.spendLimitDuration &&
                          Boolean(errors.spendLimitDuration)
                        }
                      >
                        <MenuItem value="">
                          <em>Select spend limit duration</em>
                        </MenuItem>
                        <MenuItem value="MONTHLY">Monthly</MenuItem>
                        <MenuItem value="ANNUALLY">Annually</MenuItem>
                        <MenuItem value="TRANSACTION">Transaction</MenuItem>
                        <MenuItem value="FOREVER">Forever</MenuItem>
                      </Select>
                      {touched.spendLimitDuration &&
                        Boolean(errors.spendLimitDuration) && (
                          <FormHelperText error>
                            {errors.spendLimitDuration}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                  <FormControl size="small" fullWidth>
                    <InputLabel
                      id="restriction-flag-select-helper-label"
                      variant="standard"
                      error={
                        touched.restrictionFlag &&
                        Boolean(errors.restrictionFlag)
                      }
                    >
                      Merchant Categories Restriction Flag
                    </InputLabel>
                    <Select
                      variant="standard"
                      margin="dense"
                      labelId="restriction-flag-select-helper-label"
                      id="restriction-flag-select-helper"
                      name="restrictionFlag"
                      value={values.restrictionFlag}
                      label="Restriction Flag"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.restrictionFlag &&
                        Boolean(errors.restrictionFlag)
                      }
                    >
                      <MenuItem value="">
                        <em>Select Restriction Flag</em>
                      </MenuItem>
                      <MenuItem value="NONE">None</MenuItem>
                      <MenuItem value="ALLOWED">Allow</MenuItem>
                      <MenuItem value="BLOCKED">Block</MenuItem>
                    </Select>
                    {touched.restrictionFlag &&
                      Boolean(errors.restrictionFlag) && (
                        <FormHelperText error>
                          {errors.restrictionFlag}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>

                {(values.restrictionFlag === "ALLOWED" ||
                  values.restrictionFlag === "BLOCKED") && (
                  <Grid item mx={{ xs: 2, sm: 4, md: 6, lg: 10 }} my={2} xs={12}>
                    <MccRestrictionTable parentCallback={handleChosenMccs} />
                  </Grid>
                )}
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>

        <DialogActions style={{ padding: "1rem 2rem" }}>
          <Button
            autoFocus
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
          >
            save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
