import { Grid, Stack, Typography } from "@mui/material";
import { PatternFormat } from "react-number-format";

function OwnerDetails({ owner }: any) {
  return (
    <>
      <Grid container p={1}>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Full Name: </Typography>
            <Typography variant="subtitle2">{owner?.name}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Date of Birth:</Typography>
            <Typography variant="subtitle2">{owner?.dob}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Ownership Percentage:</Typography>
            <Typography variant="subtitle2">
              {owner?.ownershipPercentage} %
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Phone:</Typography>
            <Typography variant="subtitle2">
              +1{" "}
              <PatternFormat
                value={owner?.phone}
                displayType="text"
                type="text"
                format="### ### ####"
              />
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Email:</Typography>
            <Typography variant="subtitle2">{owner?.email}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Country:</Typography>
            <Typography variant="subtitle2">{owner?.country}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Address 1:</Typography>
            <Typography variant="subtitle2">{owner?.address1}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Address 2:</Typography>
            <Typography variant="subtitle2">
              {owner?.address2 || "--"}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">City:</Typography>
            <Typography variant="subtitle2">{owner?.city}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">State:</Typography>
            <Typography variant="subtitle2">{owner?.state}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Zip Code:</Typography>
            <Typography variant="subtitle2">{owner?.zip}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default OwnerDetails;
