import { Chip, Divider, Grid, TableSortLabel, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import * as React from "react";
import { toast } from "react-toastify";
import instance from "../../services/AxiosInterceptor";
import BackDrop from "../BackDrop";
import { navy } from "../menu/Drawer";
import EmptyTable from "../onboard/owner/EmptyTable";
import { formatCentToUsd } from "../transaction/Transaction";
import FundModal from "./FundModal";
import SearchPayment from "./SearchPayment";
import ViewPaymentModal from "./ViewPaymentModal";
interface Column {
  id: "amount" | "settlement_date" | "status" | "createdAt" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "createdAt", label: "Created At", minWidth: 100 },
  {
    id: "amount",
    label: "Amount",
    minWidth: 100,
  },
  { id: "settlement_date", label: "Settlement Date", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

export default function Payments() {
  const [payments, setPayments] = React.useState([]);
  const [totalElements, setTotalElements] = React.useState(0);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  type Order = "asc" | "desc";
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");

  const [search, setSearch] = React.useState("");
  const [searchBy, setSearchBy] = React.useState("name");

  const [backdrop, setBackdrop] = React.useState(true);

  const fetchPayments = React.useCallback(() => {
    //
    setBackdrop(true);

    instance
      .get("/bank/payments", {
        params: {
          page: page,
          size: rowsPerPage,
          order: order,
          sort: orderBy,
          [searchBy]: search,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        //
        //
        const newPage = page;
        setPage(newPage);

        setPayments(data.content);
        setTotalElements(data.totalElements);
      })
      .catch((error) => {
        toast.error(error.response);
      })
      .finally(() => {
        setBackdrop(false);
      });
  }, [page, rowsPerPage, order, orderBy, search, searchBy]);

  React.useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const transformValue = (value: any) => {
    switch (value.toUpperCase()) {
      case "PENDING":
        return (
          <Chip
            color="warning"
            label={value.toUpperCase()}
            variant="outlined"
            size="small"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "1.5px" }}
          />
        );
      default:
        return (
          <Chip
            color="error"
            label="Unknown"
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "1.5px" }}
          />
        );
    }
  };

  const setSearchCriteria = (search: any, searchBy: any) => {
    setSearch(search);
    setSearchBy(searchBy);
  };

  return (
    <>
      {backdrop ? (
        <BackDrop open={backdrop} />
      ) : (
        <Grid container py={5}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Typography
              fontWeight={"bold"}
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
              px={2}
              py={0.75}
              border={"solid 0.5px silver"}
              bgcolor={"rgba(27,59,101, .1)"}
              color={navy}
            >
              Funds
            </Typography>
            <Divider />
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow sx={{ display: { xs: 'table-row', sm: 'none' } }}>
                    <TableCell align="left" colSpan={6} size="small">
                      <SearchPayment setSearchCriteria={setSearchCriteria} />
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ display: { xs: 'table-row', sm: 'none' } }}>
                    <TableCell align="left" colSpan={6} size="small">
                      <FundModal fetchPayments={fetchPayments} />
                    </TableCell>
                  </TableRow >
                  <TableRow sx={{ display: { xs: 'none', sm: 'table-row' } }}>
                    <TableCell align="left" colSpan={4} size="small">
                      <SearchPayment setSearchCriteria={setSearchCriteria} />
                    </TableCell>
                    <TableCell align="right" colSpan={4} size="small">
                      <FundModal fetchPayments={fetchPayments} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {payments.length === 0 ? (
                      <EmptyTable message="No data found!" />
                    ) : (
                      <>
                        {columns.map((column) => (
                          <TableCell
                            size="small"
                            sortDirection={false}
                            key={column.id}
                            align={column.label === 'Action' ? 'right' : column.align}
                            sx={{
                              minWidth: column.minWidth,
                              fontVariant: "h4",
                              fontWeight: "bold",
                              backgroundColor: "",
                              display: { xs: column.label === 'Settlement Date' || column.label === 'Status' ? 'none' : 'table-cell', sm: 'table-cell' },
                              p: { xs: '6px', sm: '6px 16px' }
                            }}
                          >
                            {column.label}
                            {column.id !== "action" && (
                              <TableSortLabel
                                sx={{
                                  "& .MuiTableSortLabel-icon": {
                                    color: "black !important",
                                  },
                                }}
                                active={column.id === orderBy}
                                direction={order}
                                onClick={(event) => {
                                  if (column.id === orderBy) {
                                    order === "asc"
                                      ? setOrder("desc")
                                      : setOrder("asc");
                                  }
                                  setOrderBy(column.id);
                                }}
                              ></TableSortLabel>
                            )}
                          </TableCell>
                        ))}
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((row: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.transfer_uuid}
                      >
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} key="createdAt" size="small">
                          <Typography variant="subtitle2">
                            {moment(row.created_at).format(
                              "YYYY-MM-DD HH:mm z"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} key="amount" size="small">
                          {formatCentToUsd(row?.amount)}
                        </TableCell>
                        <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} key="settlement_date" size="small">
                          {row.expected_settlement_date}
                        </TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} key="status" size="small">
                          {transformValue(row.status)}
                        </TableCell>
                        <TableCell align="right" size="small">
                          <ViewPaymentModal user={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
    </>
  );
}
