import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import {
  Box,
  Button,
  Grid,
  Typography
} from "@mui/material";
import { filesize } from "filesize";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import instance from "../../services/AxiosInterceptor";

export function UploadReceipt({
  upload_uuid,
  transaction_uuid,
  onFileUpload,
}: any) {
  //
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState("");

  const onDrop = useCallback(
    //
    (acceptedFiles: any, fileRejections: any) => {
      //

      setFiles(files.concat(...acceptedFiles));

      fileRejections.forEach((file: any) => {
        file.errors.forEach((err: any) => {
          if (err.code === "file-too-large") {
            console.log(`Error: ${err.message}`);
            setErrors(
              "Max file size should be " +
              JSON.stringify(
                filesize(10485760, { base: 2, standard: "jedec" })
              )
            );
          }

          if (err.code === "file-invalid-type") {
            console.log(`Error: ${err.message}`);
            setErrors("Acceptable file types are images, text, and pdf.");
          }

          if (err.code.length > 0) {
            console.error(`Error: ${err.message}`);
          }
        });
      });

      if (fileRejections.length > 0) return;

      let formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      formData.append("upload_uuid", upload_uuid);
      formData.append("transaction_uuid", transaction_uuid);

      instance
        .post("/upload-receipt", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          onFileUpload(acceptedFiles[0]);
        })
        .catch(() => { })
        .finally(() => { });
    },
    [files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 3,
    maxSize: 2097152 * 5,
    multiple: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "text/plain": [],
      "application/pdf": [],
    },
    onDrop,
  });

  return (
    <>
      <Grid
        item
        {...getRootProps({
          className: "dropzone",
        })}
      >
        <Button
          size="small"
          style={{ color: "gray", border: "1px solid gray" }}
          variant="outlined"
          component="label"
          onClick={() => setErrors("")}
          endIcon={<DriveFolderUploadIcon />}
          fullWidth
        >
          Choose File
          <input
            id="file-upload"
            type="file"
            name="file"
            hidden
            {...getInputProps({})}
          />
        </Button>
      </Grid>

      <Box width="100%" />

      <Box>
        {errors.length !== 0 && (
          <Typography color="error" py={2}>
            {errors}
          </Typography>
        )}
      </Box>
    </>
  );
}
