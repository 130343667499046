import { ChevronRight } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import _ from "lodash";
import React, { useEffect } from "react";
import { PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import * as yup from "yup";
import { BusinessData } from "../../../models/BusinessData";
import instance from "../../../services/AxiosInterceptor";
import BackDrop from "../../BackDrop";
interface BusinessFormProps {
  handleNext: () => void;
  setErrorIndex: (i: number | null) => void;
}

function Business({ handleNext, setErrorIndex }: BusinessFormProps) {
  const [backdrop, setBackdrop] = React.useState(true);
  //
  const validationSchema = yup.object({
    name: yup.string().max(255).required(),
    type: yup.string().max(255).required(),
    ein: yup.string().required(),
    dba: yup.string().max(255).required(),
    url: yup.string().url().required(),
    est: yup.string().required("Established date is required."),
    phone: yup.string().required(),
    phoneType: yup.string().required("Phone Type field is required"),
    phoneCode: yup.string().required("Phone Code field is required"),
    phoneExt: yup.string(),
    address1: yup.string().max(255).required(),
    address2: yup.string().max(255).optional(),
    city: yup.string().max(255).required(),
    state: yup.string().required(),
    country: yup.string().max(255).required(),
    zip: yup.string().min(5).max(10).required(),
  });

  const initialValues = {
    business_uuid: "",
    name: "",
    type: "",
    ein: "",
    url: "",
    est: "",
    dba: "",
    phone: "",
    phoneType: "",
    phoneCode: "",
    phoneExt: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const request: BusinessData = {
        name: values.name,
        ein: values.ein.replaceAll("-", ""),
        dba: values.dba,
        type: values.type,
        est: values.est,
        url: values.url,
        phone: values.phone.replaceAll(" ", ""),
        phone_type: values.phoneType,
        phone_code: values.phoneCode,
        phone_ext: values.phoneExt,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        zip: values.zip,
      };

      setBackdrop(true);

      instance
        .post("/onboard/business", request)
        .then((response) => {
          handleNext();
          setErrorIndex(null);
        })
        .catch((error) => {
          setErrorIndex(0);
          toast.error(error.message || "Error!");
        })
        .finally(() => {
          setBackdrop(false);
        });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    values,
    handleChange,
    handleBlur,
  } = formik;

  useEffect(() => {
    instance
      .get("/onboard/business")
      .then((response) => {
        initialValues.business_uuid = response.data.business_uuid ?? "";
        initialValues.name = response.data.name ?? "";
        initialValues.type = response.data.type ?? "";
        initialValues.dba = response.data.dba ?? "";
        initialValues.est = response.data.est ?? "";
        initialValues.ein = response.data.ein ?? "";
        initialValues.url = response.data.url ?? "";
        initialValues.phone = response.data.phone ?? "";
        initialValues.phoneType = response.data.phone_type ?? "";
        initialValues.phoneCode = response.data.phone_code ?? "";
        initialValues.phoneExt = response.data.phone_ext ?? "";
        initialValues.address1 = response.data.address1 ?? "";
        initialValues.address2 = response.data.address2 ?? "";
        initialValues.city = response.data.city ?? "";
        initialValues.state = response.data.state ?? "";
        initialValues.country = response.data.country ?? "";
        initialValues.zip = response.data.zip ?? "";

        setErrorIndex(null);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setBackdrop(false);
      });
  }, []);

  return (
    <>
      <BackDrop open={backdrop} />
      {!backdrop && (
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit} id="validation-forms">
            <Grid container spacing={3}>

              <Grid item xs={12} mt={3}>
                <Typography fontWeight={"bold"} color={""}>Business Details</Typography>
                <Divider />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="name"
                    name="name"
                    label="Business Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    fullWidth
                    autoComplete="given-name"
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    label="Doing Business As"
                    id="dba"
                    name="dba"
                    placeholder="Doing Business As "
                    value={values.dba}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.dba && Boolean(errors.dba)}
                    helperText={touched.dba && errors.dba}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <PatternFormat
                    size="small"
                    variant="outlined"
                    label="Business EIN"
                    customInput={TextField}
                    mask="_"
                    format="##-#######"
                    id="ein"
                    name="ein"
                    placeholder="00 0000000"
                    value={_.replace(values.ein, "-", "")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ein && Boolean(errors.ein)}
                    helperText={touched.ein && errors.ein}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel
                      error={touched.type && Boolean(errors.type)}
                      id="type-select-helper-label"
                    >
                      Type
                    </InputLabel>
                    <Select
                      labelId="type-select-helper-label"
                      id="type-select-helper"
                      name="type"
                      value={values.type}
                      label="Type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.type && Boolean(errors.type)}
                    >
                      <MenuItem value="">
                        <em>Select business type</em>
                      </MenuItem>
                      <MenuItem value="CORPORATION">Corporation</MenuItem>
                      <MenuItem value="PARTNERSHIP">Partnership</MenuItem>
                      <MenuItem value="SOLE_PROPRIETORSHIP">Sole Proprietorship</MenuItem>
                      <MenuItem value="NON_PROFIT">Non profit</MenuItem>
                      <MenuItem value="LLC">Limited Liability Company (LLC)</MenuItem>
                    </Select>
                    {touched.type && Boolean(errors.type) && (
                      <FormHelperText error>{errors.type}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    label="Business Website"
                    id="url"
                    name="url"
                    placeholder="www.example.com"
                    value={values.url}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.url && Boolean(errors.url)}
                    helperText={touched.url && errors.url}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel
                      error={touched.est && Boolean(errors.est)}
                      id="est-select-helper-label"
                    >
                      Established Year
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="est-select-helper-label"
                      id="est-select-helper"
                      name="est"
                      value={values.est}
                      label="EstablishedYear"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.est && Boolean(errors.est)}
                    >
                      <MenuItem value="">
                        <em>Select established year</em>
                      </MenuItem>
                      {_.range(new Date().getFullYear(), 1999).map((year: any) => {
                        return <MenuItem key={year} value={year}>{year}</MenuItem>
                      })}
                    </Select>
                    {touched.est && Boolean(errors.est) && (
                      <FormHelperText error>{errors.est}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} mt={3}>
                <Typography fontWeight={"bold"} color={""}>Phone Details</Typography>
                <Divider />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel
                      error={touched.phoneType && Boolean(errors.phoneType)}
                      id="phoneType-select-helper-label"
                    >
                      Phone Type
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="phoneType-select-helper-label"
                      id="phoneType-select-helper"
                      name="phoneType"
                      value={values.phoneType}
                      label="Phone Type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phoneType && Boolean(errors.phoneType)}
                    >
                      <MenuItem value="">
                        <em>Select phone type</em>
                      </MenuItem>
                      <MenuItem value="HOME">Home</MenuItem>
                      <MenuItem value="MOBILE">Mobile</MenuItem>
                      <MenuItem value="WORK">Work</MenuItem>
                    </Select>
                    {touched.phoneType && Boolean(errors.phoneType) && (
                      <FormHelperText error>{errors.phoneType}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel
                      error={touched.phoneCode && Boolean(errors.phoneCode)}
                      id="phoneCode-select-helper-label"
                    >
                      Phone Code
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="phoneCode-select-helper-label"
                      id="phoneCode-select-helper"
                      name="phoneCode"
                      value={values.phoneCode}
                      label="Phone Code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phoneCode && Boolean(errors.phoneCode)}
                    >
                      <MenuItem value="">
                        <em>Select phone code</em>
                      </MenuItem>
                      <MenuItem value="1">+1</MenuItem>
                    </Select>
                    {touched.phoneCode && Boolean(errors.phoneCode) && (
                      <FormHelperText error>{errors.phoneCode}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <PatternFormat
                    size="small"
                    variant="outlined"
                    label="Business Phone"
                    customInput={TextField}
                    format="### ### ####"
                    mask="_"
                    id="phone"
                    name="phone"
                    placeholder="123 456 7890"
                    value={_.replace(values.phone, " ", "")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="phone-ext"
                    name="phoneExt"
                    label="Phone Ext."
                    value={values.phoneExt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phoneExt && Boolean(errors.phoneExt)}
                    helperText={touched.phoneExt && errors.phoneExt}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} mt={3}>
                <Typography fontWeight={"bold"} color={""}>Address Details</Typography>
                <Divider />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="address1"
                    label="Address 1"
                    name="address1"
                    placeholder="Address line 1 "
                    value={values.address1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.address1 && Boolean(errors.address1)}
                    helperText={touched.address1 && errors.address1}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    label="Address 2"
                    id="address2"
                    name="address2"
                    placeholder="Address line 2"
                    value={values.address2}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    label="City"
                    id="city"
                    name="city"
                    placeholder="City"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel
                      error={touched.state && Boolean(errors.state)}
                      id="state-select-helper-label"
                    >
                      State
                    </InputLabel>
                    <Select
                      labelId="state-select-helper-label"
                      id="state-select-helper"
                      name="state"
                      value={values.state}
                      label="State"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.state && Boolean(errors.state)}
                    >
                      <MenuItem value="">
                        <em>Select State</em>
                      </MenuItem>
                      <MenuItem value="AL">Alabama</MenuItem>
                      <MenuItem value="AK">Alaska</MenuItem>
                      <MenuItem value="AZ">Arizona</MenuItem>
                      <MenuItem value="AR">Arkansas</MenuItem>
                      <MenuItem value="CA">California</MenuItem>
                      <MenuItem value="CO">Colorado</MenuItem>
                      <MenuItem value="CT">Connecticut</MenuItem>
                      <MenuItem value="DE">Delaware</MenuItem>
                      <MenuItem value="DC">District Of Columbia</MenuItem>
                      <MenuItem value="FL">Florida</MenuItem>
                      <MenuItem value="GA">Georgia</MenuItem>
                      <MenuItem value="HI">Hawaii</MenuItem>
                      <MenuItem value="ID">Idaho</MenuItem>
                      <MenuItem value="IL">Illinois</MenuItem>
                      <MenuItem value="IN">Indiana</MenuItem>
                      <MenuItem value="IA">Iowa</MenuItem>
                      <MenuItem value="KS">Kansas</MenuItem>
                      <MenuItem value="KY">Kentucky</MenuItem>
                      <MenuItem value="LA">Louisiana</MenuItem>
                      <MenuItem value="ME">Maine</MenuItem>
                      <MenuItem value="MD">Maryland</MenuItem>
                      <MenuItem value="MA">Massachusetts</MenuItem>
                      <MenuItem value="MI">Michigan</MenuItem>
                      <MenuItem value="MN">Minnesota</MenuItem>
                      <MenuItem value="MS">Mississippi</MenuItem>
                      <MenuItem value="MO">Missouri</MenuItem>
                      <MenuItem value="MT">Montana</MenuItem>
                      <MenuItem value="NE">Nebraska</MenuItem>
                      <MenuItem value="NV">Nevada</MenuItem>
                      <MenuItem value="NH">New Hampshire</MenuItem>
                      <MenuItem value="NJ">New Jersey</MenuItem>
                      <MenuItem value="NM">New Mexico</MenuItem>
                      <MenuItem value="NY">New York</MenuItem>
                      <MenuItem value="NC">North Carolina</MenuItem>
                      <MenuItem value="ND">North Dakota</MenuItem>
                      <MenuItem value="OH">Ohio</MenuItem>
                      <MenuItem value="OK">Oklahoma</MenuItem>
                      <MenuItem value="OR">Oregon</MenuItem>
                      <MenuItem value="PA">Pennsylvania</MenuItem>
                      <MenuItem value="RI">Rhode Island</MenuItem>
                      <MenuItem value="SC">South Carolina</MenuItem>
                      <MenuItem value="SD">South Dakota</MenuItem>
                      <MenuItem value="TN">Tennessee</MenuItem>
                      <MenuItem value="TX">Texas</MenuItem>
                      <MenuItem value="UT">Utah</MenuItem>
                      <MenuItem value="VT">Vermont</MenuItem>
                      <MenuItem value="VA">Virginia</MenuItem>
                      <MenuItem value="WA">Washington</MenuItem>
                      <MenuItem value="WV">West Virginia</MenuItem>
                      <MenuItem value="WI">Wisconsin</MenuItem>
                      <MenuItem value="WY">Wyoming</MenuItem>
                    </Select>
                    {touched.state && Boolean(errors.state) && (
                      <FormHelperText error>{errors.state}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    label="Zip Code"
                    id="zip"
                    name="zip"
                    placeholder="Zip/Postal Code"
                    value={values.zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.zip && Boolean(errors.zip)}
                    helperText={touched.zip && errors.zip}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel
                      error={touched.country && Boolean(errors.country)}
                      id="country-select-helper-label"
                    >
                      Country
                    </InputLabel>
                    <Select
                      labelId="country-select-helper-label"
                      id="country-select-helper"
                      name="country"
                      value={values.country}
                      label="Country"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.country && Boolean(errors.country)}
                    >
                      <MenuItem value="">
                        <em>Select country</em>
                      </MenuItem>
                      <MenuItem value="USA">USA</MenuItem>
                    </Select>
                    {touched.country && Boolean(errors.country) && (
                      <FormHelperText error>{errors.country}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={{ my: 3, ml: 1 }}
                    type="submit"
                    size="small"
                    endIcon={<ChevronRight fontSize="small" />}
                  >
                    next
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}

export default Business;
