// import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
// import _ from "lodash";
// import { Doughnut } from "react-chartjs-2";

// import ChartDataLabels from "chartjs-plugin-datalabels";

// ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

// const options: any = {
//   tooltips: {
//     enabled: false
//   },
//   cutout: 55,
//   legend: { display: true, position: "bottom" },
//   plugins: {
//     datalabels: {
//       formatter: function (value: any, context: any) {

//         // let sum = 0;
//         // context.chart.data.datasets[context.datasetIndex].data.forEach((element: any) => {
//         //   if (element !== undefined) {
//         //     sum += element;
//         //   }
//         // });
//         // let result = (Number(value ? Math.round(value) : 0) * 100) / sum;
//         // return result ? `${+result.toFixed(2)}%` : ""

//         return value ? +value : "";

//       },
//       color: "white",
//       font: {
//         weight: "bold",
//         size: "15px"
//       },
//       // backgroundColor: function(context:any) {
//       //   return "white";
//       // },
//       // borderRadius: 1,
//       // fontWeight: "12px",
//       // borderWidth: 1,
//       // padding: 6
//     },
//     legend: {
//       position: "bottom",
//       labels: {
//         boxWidth: 13
//       },
//     },
//     title: {
//       display: true,
//       text: 'Transactions Status',
//       font: {
//         weight: "bold",
//         size: "13px"
//       },
//     }
//   }
// };

// export function TransactionStatusChart(props: any) {

//   const approvedTransactionCount = _.find(props.transactionStatusCount, { status: "APPROVED" });

//   let notApprovedTransactionCount = 0;
//   props.transactionStatusCount?.map((ts: any) => {
//     if (ts.status !== "APPROVED") {
//       notApprovedTransactionCount += ts.count;
//     }
//   })

//   const data = {
//     labels: ["Approved", "Not Approved"],
//     datasets: [
//       {
//         label: "# of Transactions",
//         data: [
//           approvedTransactionCount?.count,
//           notApprovedTransactionCount],
//         backgroundColor: [
//           "green",
//           "red",
//         ],
//         borderColor: [
//           "silver",
//         ],
//         borderWidth: 1,
//       },
//     ],
//   };

//   return <Doughnut data={data} options={options} />;
// }


import { Box } from '@mui/material';
import _ from "lodash";
import color from 'material-ui-colors/dist/amber';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
const COLORS = ['green', 'red'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (
  { cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }
    : {
      cx: number;
      cy: number;
      midAngle: number;
      innerRadius: number;
      outerRadius: number;
      percent: number;
      index: number;
      value: any;
    }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="middle" fontWeight={"bold"}>
      {value}
    </text>
  );
};

export function TransactionStatusChart(props: any) {


  const approvedTransactionCount = _.find(props.transactionStatusCount, { status: "APPROVED" });

  let notApprovedTransactionCount = 0;
  props.transactionStatusCount?.map((ts: any) => {
    if (ts.status !== "APPROVED") {
      notApprovedTransactionCount += ts.count;
    }
  })


  const data = [
    { name: 'Approved', value: approvedTransactionCount?.count },
    { name: 'Not Approved', value: notApprovedTransactionCount > 0 ? notApprovedTransactionCount : "" },
  ]

  const renderLegendText = (value: string) => {
    return <span style={{ color: "black", fontSize: "small" }}>{value}</span>;
  };

  return (
    <Box sx={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            labelLine={false}
            label={renderCustomizedLabel}
            innerRadius={70}
            // outerRadius={110}
            fill="#8884d8"
            dataKey="value"
            name="pv of pages"
            style={{ outline: 'none' }}
          >
            <Label value="Transactions" position="center" fontWeight={"bold"} />
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend formatter={renderLegendText} />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}
