import { ChevronRight } from "@mui/icons-material";
import { Grid, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export default function Welcome() {
  const navigate = useNavigate();

  return (
    <Grid container alignItems={"center"} justifyContent={"center"} my={5}>
      <Grid item p={10}>
        <Card>
          <CardContent style={{ margin: "5rem" }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h5" component="div">
                Before you start onboarding...
              </Typography>
            </Box>
            <Typography variant="body1" component="div" my={5}>
              Please note that you need following information to provide:
            </Typography>
            <Typography variant="body2">
              1. Business details such as Legal Name, EIN, Phone Number, and
              Address
              <br />
              2. Owner details including Date of Birth, SSN, Phone Number, and
              Address
              <br />
            </Typography>
          </CardContent>
          <CardActions>
            <Grid item xs={12} mb={3}>
              <Stack direction="row" justifyContent="right" mx={5}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ my: 3, ml: 1 }}
                  type="submit"
                  onClick={() => {
                    navigate("/onboard");
                  }}
                  endIcon={<ChevronRight fontSize="small" />}
                >
                  start
                </Button>
              </Stack>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
