import { Box } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Accounting from "./accounting/Accounting";
import Footer from "./components/Footer";
import SignIn from "./components/auth/SignIn";
import BankingTab from "./components/bank/BankingTab";
import Cards from "./components/cards/Cards";
import Charges from "./components/charges/Charges";
import Dashboard from "./components/dashboard/Dashboard";
import MiniDrawer from "./components/menu/Drawer";
import Setting from "./components/menu/Setting";
import Onboard from "./components/onboard/Onboard";
import Welcome from "./components/onboard/Welcome";
import CardholderAgreement from "./components/privacy/CardholderAgreement";
import ESign from "./components/privacy/Esign";
import Terms from "./components/privacy/Terms";
import Support from "./components/support/Support";
import Transaction from "./components/transaction/Transaction";
import InitiateForgotPassword from "./components/users/InitiateForgotPassword";
import ResetPassword from "./components/users/ResetPassword";
import SetPassword from "./components/users/SetPassword";
import Users from "./components/users/Users";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import { AuthProvider } from "./utils/AuthProvider";
import IdleAppChecker from "./utils/IdleAppChecker";

function App() {

  return (
    <BrowserRouter>
      <AuthProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{
            fontWeight: "bold"
          }}
        />
        <IdleAppChecker />
        <Box
          sx={{
            paddingY: 8 ,
            paddingX: { xs: 2, sm: 3, md: 4, lg: 6, xl: 8 },
            display: "flex",
          }}
        >
          <MiniDrawer />
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/onboard/welcome" element={<Welcome />} />
              <Route path="/onboard" element={<Onboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/cards" element={<Cards />} />
              <Route path="/banking" element={<BankingTab />} />
              <Route path="/accounting" element={<Accounting />} />
              <Route path="/support" element={<Support />} />
              <Route path="/transactions" element={<Transaction />} />
              <Route path="/charges" element={<Charges />} />
              <Route path="/setting" element={<Setting />} />
            </Route>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/esign" element={<ESign />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/cardholder-agreement" element={<CardholderAgreement />} />
            <Route path="/app/onboard/set-password" element={<SetPassword />} />
            <Route
              path="/users/reset-password"
              element={<ResetPassword />}
            />
            <Route
              path="/users/initiate-forgot-password"
              element={<InitiateForgotPassword />}
            />
          </Routes>
        </Box>
        <Box
          display={"column"}
          m={0}
          position={"fixed"}
          bottom={0}
          width={"100vw"}
        >
          <Footer />
        </Box>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
