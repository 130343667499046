import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleIcon from "@mui/icons-material/People";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SettingsIcon from "@mui/icons-material/Settings";
import SpeedIcon from "@mui/icons-material/Speed";
import StartIcon from "@mui/icons-material/Start";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";

const getIcon = (text: any) => {
    switch (text) {
        case "Onboard":
            return <StartIcon color="primary" />;
        case "Dashboard":
            return <SpeedIcon color="primary" />;
        case "Banking":
            return <AccountBalanceIcon color="primary" />;
        case "Accounting":
            return <PointOfSaleIcon color="primary" />
        case "Users":
            return <PeopleIcon color="primary" />;
        case "Cards":
            return <CreditCardIcon color="primary" />;
        case "Charges":
            return <AccountTreeIcon color="primary" />;
        case "Transactions":
            return <ReceiptLongIcon color="primary" />;
        case "Support":
            return <ContactSupportOutlinedIcon color="primary" />;
        case "Setting":
            return <SettingsIcon color="primary" />;
        default:
            throw new Error("Invalid Icon!")
    }
};

export const navy = "#1b3b65";

export default function SidebarMenu(props: any) {
    //
    const location = useLocation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ p: 1 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                    >
                        <MenuIcon sx={{ color: 'white' }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Divider />


                {props.listItems.map((text: any, index: any) => (
                    <ListItem
                        key={index}
                        disablePadding
                        component={Link}
                        to={"/" + text.toLowerCase()}
                        style={
                            // selectedIndex === index
                            location.pathname.includes(text.toLowerCase())
                                ? { borderRight: `2.5px solid ${navy}` }
                                : {}
                        }
                    >
                        <ListItemButton
                            style={{
                                textDecoration: "none",
                                border: "none",
                            }}
                            selected={location.pathname.includes(text.toLowerCase())}
                        >
                            <ListItemIcon sx={{ border: "", marginLeft: "5px" }}>
                                {getIcon(text)}
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontWeight: "bold",
                                    color: "primary",
                                    fontSize: "small",
                                }}
                                primary={text}
                                style={{ color: "black" }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}


            </Menu>
        </React.Fragment>
    );
}
