import { AttachMoneyOutlined } from "@mui/icons-material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Grid, Paper, Box, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import instance from "../../services/AxiosInterceptor";
import { pointer } from "../../styles";
import BackDrop from "../BackDrop";
import { CardStatusChart } from "../charts/CardStatusChart";
import { EmptyStatusChart } from "../charts/EmptyStatusChart";
import { FundChart } from "../charts/FundChart";
import { TransactionStatusChart } from "../charts/TransactionStatusChart";
import { UserStatusChart } from "../charts/UserStatusChart";
import { formatCentToUsd } from "../transaction/Transaction";
import HoverSocialCard from "./HoverSocialCard";


const Dashboard = () => {

  const theme = useTheme();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
   // const { logout }: any = React.useContext(AuthContext);


  useEffect(() => {
    instance
      .get("/dashboard")
      .then((response) => {
        setData(response.data);
      })
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        toast.error(error)
      }).finally(
        () => {
          setLoading(false)
        }
      )
  }, []);

  const navigate = useNavigate();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: "100%"
  }));

  return (
    <>
      {loading ? (<BackDrop open={loading} />
      ) : (
        <Box width={'100%'}>
          <Grid container spacing={2} mt={5}
            justifyContent={"center"}
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={pointer}
                onClick={() => {
                  navigate("/users", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Total Users"
                  secondary={data?.users}
                  iconPrimary={PersonOutlineIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={pointer}
                onClick={() => {
                  navigate("/cards", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Total Cards"
                  secondary={data?.cards}
                  iconPrimary={CreditCardIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={pointer}
                onClick={() => {
                  navigate("/charges", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Current Balance"
                  secondary={formatCentToUsd(data?.balance).slice(1)}
                  iconPrimary={AttachMoneyOutlined}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={pointer}
                onClick={() => {
                  navigate("/transactions", { replace: true });
                }}>
                <HoverSocialCard
                  primary="All Transactions"
                  secondary={data?.transactions}
                  iconPrimary={ReceiptLongIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
          </Grid>

          <Grid width={'100%'} spacing={4} container mt={5} mb={5} justifyContent={"center"} alignItems="center">
            <Grid item xs={12} sm={6} lg={4}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100%'}>
                <Box width={'100%'} height={'100%'}>
                  {
                    (_.sumBy(data?.user_status_count, (u: any) => {
                      return u.status;
                    })) === 0 ? <EmptyStatusChart text="Users Status" /> :
                      <UserStatusChart userStatusCount={data?.user_status_count} />
                  }
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100%'}>
                <Box width={'100%'} height={'100%'}>
                  {
                    (_.sumBy(data?.card_status_count, (u: any) => {
                      return u.status;
                    })) === 0 ? <EmptyStatusChart text="Cards Status" /> :
                      <CardStatusChart cardStatusCount={data?.card_status_count} />
                  }
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100%'}>
                <Box width={'100%'} height={'100%'}>
                  {
                    (_.sumBy(data?.transaction_status_count, (u: any) => {
                      return u.status;
                    })) === 0 ? <EmptyStatusChart text="Transactions Status" /> :
                      <TransactionStatusChart transactionStatusCount={data?.transaction_status_count} />
                  }
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container my={5} mt={10} justifyContent={"center"}
            alignItems="center">
            <Typography sx={{ textAlign: 'center', mb: 3, color: "gray" }}  fontWeight={"bold"}>Monthly Payments</Typography>
          <FundChart chargePerMonth={data?.charge_per_month} />
        </Grid>

        </Box >
      )}
    </>
  );
};

export default Dashboard;
