import { EditOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import {
  AppBar,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
} from "@mui/material";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { toast } from "react-toastify";
import { pointer } from "../../styles";
import instance from "../../services/AxiosInterceptor";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface EditUserModalProps {
  user: { email: string; role: string; status: string; user_uuid: string };
  fetchUsers: () => void;
}
export default function EditUserModal({
  user,
  fetchUsers,
}: EditUserModalProps) {
  //

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    formik.resetForm();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const initialValues = {
    email: user.email,
    role: user.role,
    status: user.status,
  };
  
  const validationSchema = yup.object({
    email: yup.string().email().required(),
    role: yup.string().required(),
    status: yup.string().required(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      instance
        .put("/users", {
          user_uuid: user.user_uuid,
          email: values.email,
          role: values.role,
          status: values.status,
        })
        .then((response: any) => response.data)
        .then((data: any) => {
          handleClose();
          toast.success("User updated successfully!");
          fetchUsers();
        })
        .catch((error: any) => {
          toast.error(error.response.data.message);
        });
    },
  });

  const { errors, touched, values, handleSubmit, handleChange, handleBlur } =
    formik;

  return (
    <>
      <EditOutlined
        sx={{ mr: 1 }}
        style={pointer}
        fontSize="small"
        color="secondary"
        onClick={handleClickOpen}
      />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        fullWidth
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
              Edit User
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent dividers>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit} id="add-user-form">
              <Grid mx={{ xs: 2, sm: 4, md: 6, lg: 10 }} mb={5}>
                <TextField
                  type="email"
                  fullWidth
                  margin="dense"
                  size="small"
                  variant="standard"
                  id="email"
                  name="email"
                  label="User Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid mx={{ xs: 2, sm: 4, md: 6, lg: 10 }} mb={5}>
                <FormControl size="small" sx={{ width: '100%' }}>
                  <InputLabel
                    id="role-select-helper-label"
                    variant="standard"
                    error={touched.role && Boolean(errors.role)}
                  >
                    Role
                  </InputLabel>
                  <Select
                    variant="standard"
                    margin="dense"
                    labelId="role-select-helper-label"
                    id="role-select-helper"
                    name="role"
                    value={values.role}
                    label="Role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.role && Boolean(errors.role)}
                  >
                    <MenuItem value="">
                      <em>Select role</em>
                    </MenuItem>
                    <MenuItem value="USER" disabled={user.role === "USER"}>
                      User
                    </MenuItem>
                    <MenuItem
                      value="ACCOUNTANT"
                      disabled={user.role === "ACCOUNTANT"}
                    >
                      Accountant
                    </MenuItem>
                    <MenuItem value="ADMIN" disabled={user.role === "ADMIN"}>
                      Admin
                    </MenuItem>

                    {/* {getRoleMenuItems(values.role)} */}
                  </Select>
                  {touched.role && Boolean(errors.role) && (
                    <FormHelperText error>{errors.role}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid mx={{ xs: 2, sm: 4, md: 6, lg: 10 }} mb={5}>
                <FormControl size="small" sx={{ width: '100%' }}>
                  <InputLabel
                    id="status-select-helper-label"
                    variant="standard"
                    error={touched.status && Boolean(errors.status)}
                  >
                    Status
                  </InputLabel>
                  <Select
                    variant="standard"
                    margin="dense"
                    labelId="status-select-helper-label"
                    id="status-select-helper"
                    name="status"
                    value={values.status}
                    label="Status"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.status && Boolean(errors.status)}
                    disabled={
                      user.status !== "ACTIVE" &&
                      user.status !== "SUSPENDED" &&
                      user.status !== "TERMINATED"
                    }
                  >
                    <MenuItem value="">
                      <em>Select status</em>
                    </MenuItem>
                    <MenuItem
                      value="ACTIVE"
                      disabled={
                        user.status === "TERMINATED" || user.status === "ACTIVE"
                      }
                    >
                      Active
                    </MenuItem>
                    <MenuItem
                      value="SUSPENDED"
                      disabled={
                        user.status === "TERMINATED" ||
                        user.status === "SUSPENDED"
                      }
                    >
                      Suspended
                    </MenuItem>
                    <MenuItem
                      value="TERMINATED"
                      disabled={user.status === "TERMINATED"}
                    >
                      Terminated
                    </MenuItem>
                  </Select>
                  {touched.status && Boolean(errors.status) && (
                    <FormHelperText error>{errors.status}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <DialogActions>
                <Button autoFocus type="submit" variant="outlined">
                  Submit
                </Button>
              </DialogActions>
            </Form>
          </FormikProvider>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
