import VisibilityIcon from "@mui/icons-material/Visibility";
import { Avatar, Backdrop, Divider, Grid, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import moment from "moment-timezone";
import * as React from "react";
import { useState } from "react";
import instance from "../../services/AxiosInterceptor";
import { ToastError } from "../ToastError";
import { navy } from "../menu/Drawer";
import EmptyTable from "../onboard/owner/EmptyTable";
import Bank from "./Bank";
import DeleteInstitutionModal from "./DeleteInstitutionModal";
import betterment from "./logos/betterment.png";
import bofa from "./logos/bofa.png";
import charles from "./logos/charles.png";
import chase from "./logos/chase.png";
import citi from "./logos/citi.png";
import citizens from "./logos/citizens.png";
import fidelity from "./logos/fidelity.png";
import huntington from "./logos/huntington.png";
import regions from "./logos/regions.png";
import stash from "./logos/stash.png";
import td from "./logos/td.png";
import usaa from "./logos/usaa.png";
import wealthfront from "./logos/wealthfront.png";
import wellsfargo from "./logos/wellsfargo.png";
import BackDrop from "../BackDrop";

type AccountData = {
  account_uuid: string;
  created_at: string;
  created_by_uuid: any;
  created_by_name: any;
  mask: any;
  name: any;
  official_name: any;
  status: any;
  subtype: any;
  type: any;
  inst_id: any;
};
interface InstitutionData {
  institution_name: any;
  institution_uuid: any;
  accounts: AccountData[];
}

export const getInstitutionLogo = (institutionId: string) => {
  switch (institutionId) {
    case "ins_5":
      return citi;
    case "ins_12":
      return fidelity;
    case "ins_20":
      return citizens;
    case "ins_115605":
      return betterment;
    case "ins_127989":
      return bofa;
    case "ins_11":
      return charles;
    case "ins_56":
      return chase;
    case "ins_21":
      return huntington;
    case "ins_15":
      return navy;
    case "ins_19":
      return regions;
    case "ins_115614":
      return stash;
    case "ins_14":
      return td;
    case "ins_7":
      return usaa;
    case "ins_115617":
      return wealthfront;
    case "ins_127991":
      return wellsfargo;
    default:
      break;
  }
};
function BankItem(props: { row: ReturnType<any> }) {
  const pointer = { cursor: "pointer" };

  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow >
        <TableCell sx={{p: { xs: '6px', sm: '6px 16px' }}} component="th" scope="row" size="small">
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar
              alt={row.institution_name}
              src={getInstitutionLogo(row.institution_id)}
              sx={{ width: 20, height: 20 }}
            />
            <Typography variant="body2">{row.institution_name}</Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{p: { xs: '6px', sm: '6px 16px' }}} size="small">{row.accounts.length}</TableCell>
        <TableCell sx={{p: { xs: '6px', sm: '6px 16px' }}} align="right" size="small">
          <VisibilityIcon
            color="success"
            sx={{ mr: 1 }}
            style={pointer}
            fontSize="small"
            onClick={() => setOpen(!open)}
          />
          <DeleteInstitutionModal uuid={row.institution_uuid} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3} size="small">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                gutterBottom
                component="div"
              >
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Mask</TableCell>
                    <TableCell>Created by</TableCell>
                    <TableCell>Created date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    row.accounts.map((account: AccountData) => (
                      <TableRow key={account.account_uuid}>
                        <TableCell>{account.subtype}</TableCell>
                        <TableCell>{account.mask}</TableCell>
                        <TableCell>{account.created_by_name}</TableCell>
                        <TableCell component="th" scope="row">
                          {moment(account.created_at).format(
                            "YYYY-MM-DD hh:mm A z"
                          ) +
                            "(" +
                            moment.tz.guess() +
                            " Time)"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function BankList() {

  const [loading, setLoading] = useState(true);
  const [institutionData, setInstitutionData] = useState([]);

  React.useEffect(() => {
    instance
      .get("/bank/institutions")
      .then((response) => {
        setInstitutionData(response.data);
      })
      .then(() => { })
      .catch((error) => {
        ToastError(error)
      }).then(
        () => {
          setLoading(false)
        }
      );
  }, []);

  return (
    <>
      {
      loading ? <BackDrop open={loading} /> :
        <Grid container py={5}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Typography
              fontWeight={"bold"}
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
              px={2}
              py={0.75}
              border={"solid 0.5px silver"}
              bgcolor={"rgba(27,59,101, .1)"}
              color={navy}
            >
              Banks
            </Typography>
            <Divider />
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" colSpan={4} size="small">
                      <Bank />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }} size="small">
                      Bank Name
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} size="small">
                      Connected Accounts
                    </TableCell>
                    <TableCell align='right' style={{ fontWeight: "bold" }}  size="small">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {
                      <>
                        {institutionData?.length === 0 ? <EmptyTable /> :
                          institutionData.map((institution: InstitutionData) => (
                            <BankItem
                              key={institution.institution_uuid}
                              row={institution}
                            />
                          ))}
                      </>
                    }
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      }
    </>
  );
}
