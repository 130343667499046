import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { AppBar, Grid, Stack, Toolbar } from "@mui/material";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { pointer } from "../../styles";
import { formatCentToUsd } from "./Transaction";
import TransactionTable from "./TransactionTable";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function ViewTransactionModal({ transaction }: any) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <VisibilityIcon
        color="success"
        sx={{ mr: 1 }}
        style={pointer}
        fontSize="small"
        onClick={handleClickOpen}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        fullWidth
        maxWidth={"md"}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
              View Transaction
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Grid container spacing={3} p={3}>
            <Grid item xs={12} md={4} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">City: </Typography>
                <Typography variant="subtitle2">
                  {transaction?.city}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">State: </Typography>
                <Typography variant="subtitle2">
                  {transaction?.state}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">Country: </Typography>
                <Typography variant="subtitle2">
                  {transaction?.country}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">Amount: </Typography>
                <Typography variant="subtitle2">
                  {formatCentToUsd(transaction?.amount)}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">Descriptor: </Typography>
                <Typography variant="subtitle2">
                  {transaction?.descriptor}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">Network: </Typography>
                <Typography variant="subtitle2">
                  {transaction?.network}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">Card name: </Typography>
                <Typography variant="subtitle2">
                  {transaction?.card.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">Last 4: </Typography>
                <Typography variant="subtitle2">
                  {transaction?.card.last_four}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">
                  Card holder email:{" "}
                </Typography>
                <Typography variant="subtitle2">
                  {transaction?.card.user.email}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">
                  Card holder name:{" "}
                </Typography>
                <Typography variant="subtitle2">
                  {transaction?.card.user.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">MCC description </Typography>
                <Typography variant="subtitle2">
                  {transaction?.mcc !== null
                    ? transaction.mcc?.description
                    : "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">MCC category: </Typography>
                <Typography variant="subtitle2">
                  {transaction?.mcc !== null
                    ? transaction.mcc?.category
                    : "-"}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} p={1}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">
                  Transaction life cycle events:{" "}
                </Typography>
                <Typography variant="subtitle2">
                  <TransactionTable transaction={transaction} />
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
