import { Button, Grid, List, ListItemText, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { filesize } from "filesize";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import instance from "../../services/AxiosInterceptor";
import EmptyTable from "../onboard/owner/EmptyTable";
import DeleteReceiptModal from "./DeleteReceiptModal";
import { UploadReceipt } from "./UploadReceipt";

export default function ReceiptDetailsTable(props: any) {
  const [memo, setMemo] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [receipts, setReceipts] = useState([]);

  const handleFileUpload = (file: any) => {
    setUploadedFiles(uploadedFiles.concat(file));
  };

  const fetchReceipts = useCallback(() => {
    instance
      .get("/receipts?transaction_uuid=" + props.transaction_uuid)
      .then((response) => {
        setReceipts(response.data);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => { });
  }, [props.transaction_uuid]);

  useEffect(() => {
    fetchReceipts()
  }, [fetchReceipts])

  const handleClick = () => {
    instance
      .put("/upload-receipt", {
        memo: memo,
        upload_uuid: uuidv4(),
      })
      .then((response) => {
        toast.success("Submitted!");
        fetchReceipts();
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setMemo("");
        setUploadedFiles([]);
      });
  };

  return (
    <TableContainer component={Paper} >
      <Table sx={{ minWidth: { xs: '100%', md: 650 } }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={10} size="small">
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} m={1}>
                  <TextField
                    autoComplete="off"
                    id="memo"
                    label="Memo"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={memo}
                    onChange={(newValue) => setMemo(newValue.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} m={1}>
                  <UploadReceipt
                    upload_uuid={uuidv4()}
                    transaction_uuid={props?.transaction_uuid}
                    onFileUpload={handleFileUpload}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <List dense>
                    {uploadedFiles.map((file: any, index) => (
                      <ListItemText key={index} sx={{ alignItems: "left" }}>
                        {file.path} -{" "}
                        {filesize(file.size, { base: 2, standard: "jedec" })}
                      </ListItemText>
                    ))}
                  </List>
                </Grid>
                <Grid
                  item
                  xs={12} sm={6}
                  m={1}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleClick()}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: "bold", color: "gray" }} size="small">
              File Name
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "gray", display: { xs: 'none', sm: 'table-cell' } }} size="small">
              Memo
            </TableCell>
            <TableCell style={{ fontWeight: "bold", color: "gray" }} size="small">
              Approved
            </TableCell>
            <TableCell style={{ fontWeight: "bold", color: "gray" }} size="small">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {receipts?.length === 0 ? <EmptyTable message="No receipts uploaded yet!" /> : receipts?.map((receipt: any) => (
              <TableRow
                key={receipt.receipt_uuid}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" size="small">
                  {receipt.name}
                </TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} size="small">{receipt.memo ?? "- -"}</TableCell>
                <TableCell size="small">{receipt.approved ? "Yes" : "No"}</TableCell>
                <TableCell size="small">
                  <DeleteReceiptModal
                    receipt_uuid={receipt.receipt_uuid}
                    onDelete={fetchReceipts}
                  />
                </TableCell>
              </TableRow>
          ))}

        </TableBody>
      </Table>
    </TableContainer>
  );
}
