import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

function Submitted() {
  return (
    <>
      <Grid container alignItems={"center"} justifyContent={"center"} my={5}>
        <Grid item p={10}>
          <Card>
            <CardContent style={{ margin: "5rem" }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h5" component="div">
                  Congratulations!
                </Typography>
              </Box>
              <Typography variant="body1" component="div" my={5}>
                Your application submitted successfully! we review your
                information and get back to you in 1 or 2 business days.
              </Typography>
              <Typography variant="body2">
                Thanks for your cooperation!
              </Typography>
            </CardContent>
            <CardActions>
              <Grid item xs={12} mb={3}>
                <Stack direction="row" justifyContent="right" mx={5}></Stack>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Submitted;
