import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Toolbar } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function AutoLogoutModal(props: any) {

  return (
    <BootstrapDialog
      onClose={props.onCancel}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            Inactive Session
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onCancel}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        <Typography sx={{ p: 3 }}>
          {"Your session is about to expire due to inactivity. To ensure the security of your account, you will be automatically logged out in "}
          {props.remaining} {props.remaining > 1 ? "seconds" : "second"}.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={props.onLogout}
          sx={{ m: 1.5 }}
        >
          log out
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={props.onCancel}
          sx={{ m: 1.5 }}
        >
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
