import {
  Box,
  Chip,
  Divider,
  Grid,
  TableSortLabel,
  Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import * as React from "react";
import { toast } from "react-toastify";
import instance from "../../services/AxiosInterceptor";
import BackDrop from "../BackDrop";
import { navy } from "../menu/Drawer";
import EmptyTable from "../onboard/owner/EmptyTable";
import { SpendData, UserData } from "../users/Users";
import AddCardModal from "./AddCardModal";
import DeleteCardModal from "./DeleteCardModal";
import EditCardModal from "./EditCardModal";
import SearchCard from "./SearchCard";
import ViewCardModal from "./ViewCardModal";
interface Column {
  id:
  | "name"
  | "type"
  | "status"
  | "holder"
  | "spend"
  | "spend-status"
  | "createdAt"
  | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const getSpendPercentage = (spent: any, quota: any) => {
  const result = (spent / quota) * 100;
  if (result < 1 && result > 0) return 0.01;
  return result;
};
const columns: readonly Column[] = [
  {
    id: "name",
    label: "Card Name",
    minWidth: 25,
  },
  { id: "type", label: "Type", minWidth: 50 },
  { id: "status", label: "Status", minWidth: 50 },
  { id: "holder", label: "Holder", minWidth: 100 },
  { id: "spend", label: "Spend Limit", minWidth: 100 },
  { id: "spend-status", label: "Spent Status", minWidth: 100 },
  { id: "createdAt", label: "Created At", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

interface CardData {
  card_uuid: string;
  name: string;
  status: string;
  type: string;
  holder: string;
  last_four: string;
  description: string;
  card_holder_name: string;
  card_holder_email: string;
  created_at: string;
  user: UserData;
  spend: SpendData;
}

export default function Cards() {
  //
  const [fetchingCards, setFetchingCards] = React.useState(true);
  const [fetchingUsers, setFetchingUsers] = React.useState(true);

  const [totalElements, setTotalElements] = React.useState(0);

  const [cards, setCards] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  type Order = "asc" | "desc";
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");

  const [search, setSearch] = React.useState("");
  const [searchBy, setSearchBy] = React.useState("name");

  const [loggedInUserRole, setLoggedInUserRole] = React.useState<any>(null);

  const setSearchCriteria = (search: any, searchBy: any) => {
    setSearch(search);
    setSearchBy(searchBy);
  };

  const fetchCards = React.useCallback(() => {
    instance
      .get("/cards", {
        params: {
          page: page,
          size: rowsPerPage,
          order: order,
          sort: orderBy,
          [searchBy]: search,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        //
        const newPage = page;
        setPage(newPage);

        setCards(data.content);
        setTotalElements(data.totalElements);
      })
      .catch((error) => {
        toast.error(error.response);
      })
      .finally(() => {
        setFetchingCards(false);
      });
  }, [page, rowsPerPage, order, orderBy, search, searchBy])

  const fetchUsers = () => {
    instance
      .get("/users")
      .then((response) => response.data)
      .then((data) => {
        setUsers(data.content);
      })
      .catch((error) => {
        toast.error(error.response);
      })
      .finally(() => {
        setFetchingUsers(false);
      });
  };

  React.useEffect(() => {
    fetchUsers();
    fetchCards();
    setLoggedInUserRole(localStorage.getItem("role"))

  }, [loggedInUserRole, fetchCards]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const transformCardStatusValue = (value: any) => {
    switch (value) {
      case "OPEN":
        return (
          <Chip
            color="success"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "3px" }}
          />
        );
      case "INVITED":
        return (
          <Chip
            color="warning"
            label="Invited"
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "3px" }}
          />
        );
      case "PENDING_ACTIVATION":
      case "PENDING_FULFILLMENT":
        return (
          <Chip
            color="warning"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "3px" }}
          />
        );
      case "PAUSED":
        return (
          <Chip
            color="secondary"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "3px" }}
          />
        );
      case "CLOSED":
        return (
          <Chip
            color="error"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "3px" }}
          />
        );
      default:
        return (
          <Chip color="error" label="Unknown" size="small" variant="filled" />
        );
    }
  };

  return (
    <>
      <BackDrop open={fetchingCards || fetchingUsers} />
      <Grid container py={10}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <Typography
            fontWeight={"bold"}
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            px={2}
            py={0.75}
            border={"solid 0.5px silver"}
            bgcolor={"rgba(27,59,101, .1)"}
            color={navy}
          >
            Cards
          </Typography>
          <Divider />
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ display: { xs: 'table-row', sm: 'none' } }}>
                  <TableCell align="left" colSpan={6} size="small">
                    <SearchCard setSearchCriteria={setSearchCriteria} />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ display: { xs: 'table-row', sm: 'none' } }}>
                  <TableCell align="left" colSpan={6} size="small">
                    <AddCardModal fetchCards={fetchCards} users={users} />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ display: { xs: 'none', sm: 'table-row' } }}>
                  <TableCell align="left" colSpan={4} size="small">
                    <SearchCard setSearchCriteria={setSearchCriteria} />
                  </TableCell>
                  <TableCell align="right" colSpan={columns.length} size="small">
                    <AddCardModal fetchCards={fetchCards} users={users} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      size="small"
                      sortDirection={false}
                      key={column.id}
                      align={column.label === 'Action' ? 'right' : column.align}
                      sx={{
                        minWidth: column.minWidth,
                        fontVariant: "h4",
                        fontWeight: "bold",
                        display: { xs: column.label === 'Type' || column.label === 'Status' || column.label === 'Created At' || column.label === 'Spend Limit' || column.label === 'Spent Status' ? 'none' : 'table-cell', sm: 'table-cell' },
                        p: { xs: '6px', sm: '6px 16px' }
                      }}
                    >
                      {column.label}
                      {column.id !== "action" && (
                        <TableSortLabel
                          sx={{
                            "& .MuiTableSortLabel-icon": {
                              color: "black !important",
                            },
                          }}
                          active={column.id === orderBy}
                          direction={order}
                          onClick={(event) => {
                            if (column.id === orderBy) {
                              order === "asc"
                                ? setOrder("desc")
                                : setOrder("asc");
                            }
                            setOrderBy(column.id);
                          }}
                        ></TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {cards.length === 0 ? <EmptyTable message="No data found." /> :
                    cards.map((row: CardData) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.card_uuid}
                        >
                          <TableCell className="text-overflow-table" sx={{ p: { xs: '6px', sm: '6px 16px' } }} key="name" size="small">
                            {row.name}
                            {/* <Typography className="text-overflow-table" sx={{maxWidth: '100px'}} variant="body2">{row.name}</Typography> */}
                          </TableCell>
                          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} key="type" size="small" >
                            <Typography variant="body2">
                              {row.type}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} key="status" size="small">
                            {transformCardStatusValue(row.status)}
                          </TableCell>
                          <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} className="text-overflow-table" key="holder" size="small">
                            <Typography variant="body2">{row.user?.name}</Typography>{" "}
                            <Typography variant="caption">
                              {row.user?.email}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} key="spend" size="small">
                            <>
                              <Typography variant="body2">
                                {formatter.format(
                                  Number(row.spend?.spend_limit) / 100
                                )}
                              </Typography>
                              <Typography variant="caption">
                                {row.spend?.spend_limit_duration}
                              </Typography>
                            </>
                          </TableCell>
                          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} key="spend-status" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "left",
                                justifyContent: "left",
                              }}
                            >
                              <Box
                                sx={{
                                  width: 50,
                                }}
                              >
                                <div
                                  style={{
                                    background: "yellow",
                                    width: "25px",
                                    height: "25px",
                                    borderRadius: "50%",
                                    border: "solid 10px solid",
                                    backgroundImage: `conic-gradient( orange 0 ${getSpendPercentage(
                                      row.spend.spent,
                                      row.spend.spend_limit
                                    )}%, #F0F0F0 0) `,
                                  }}
                                ></div>
                              </Box>
                              <Box
                                sx={{
                                  width: 50,
                                }}
                              >
                                {(Math.round((row.spend.spent / row.spend.spend_limit) * 100)).toFixed(0) + "%"}
                              </Box>

                            </Box>
                          </TableCell>
                          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} key="createdAt" size="small">
                            <Typography variant="subtitle2">
                              {moment(row.created_at).format("YYYY-MM-DD HH:mm z")}
                            </Typography>
                          </TableCell>
                          <TableCell align="right" size="small" sx={{ minWidth: '100px', p: { xs: '6px', sm: '6px 16px' } }}>
                            <ViewCardModal card={row} />
                            {
                              loggedInUserRole === "ADMIN" ?
                                <>
                                  <EditCardModal card={row} fetchCards={fetchCards} />
                                  <DeleteCardModal card={row} fetchCards={fetchCards} />
                                </> : ""
                            }
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </>
  );
}
