import { Typography } from "@mui/material";
import OwnerDetails from "./OwnerDetails";

function OwnersList(props: any) {
  return (
    <>
      {props.owners?.map((owner: any, index: number) => {
        return (
          <div key={index} style={{ marginTop: "25px" }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Owner {index + 1}
            </Typography>
            <OwnerDetails owner={owner}></OwnerDetails>
          </div>
        );
      })}
    </>
  );
}

export default OwnersList;
