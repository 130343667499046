
import { Box } from '@mui/material';
import _ from "lodash";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
const COLORS = ['green', 'orange', 'red'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (
  { cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }
    : {
      cx: number;
      cy: number;
      midAngle: number;
      innerRadius: number;
      outerRadius: number;
      percent: number;
      index: number;
      value: any;
    }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="middle" fontWeight={"bold"}>
      {value}
    </text>
  );
};

export function CardStatusChart(props: any) {

  const openCardCount = _.find(props.cardStatusCount, { status: "OPEN" });
  const pauseCardCount = _.find(props.cardStatusCount, { status: "PAUSED" });
  const closeCardCount = _.find(props.cardStatusCount, { status: "CLOSED" });

  const data = [
    { name: 'Open', value: openCardCount?.count },
    { name: 'Paused', value: pauseCardCount?.count },
    { name: 'Closed', value: closeCardCount?.count },
  ]

  const renderLegendText = (value: string) => {
    return <span style={{ color: "black", fontSize: "small" }}>{value}</span>;
  };

  return (
    <Box sx={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            labelLine={false}
            label={renderCustomizedLabel}
            innerRadius={70}
            dataKey="value"
            name="pv of pages"
            style={{ outline: 'none' }}
          >
            <Label value="Cards" position="center" fontWeight={"bold"} />
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend formatter={renderLegendText} />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}