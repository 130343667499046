import { Link, Typography } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";

// Copyright © InfoTechCard, Inc. All Rights Reserved

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mt: 2 }}>
      {"Copyright © "}
      {new Date().getFullYear()}{" "}
      <Link color="inherit" href="https://infotechcard.com/" target="_blank" style={{ textDecoration: "none" }}>
        InfoTechCard, Inc.
      </Link>
      {" All Rights Reserved."}
    </Typography>
  );
}

export default function Footer() {
  return (
    <BottomNavigation sx={{ mx: 1 }}>
      <Copyright />
    </BottomNavigation>
  );
}
