import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Business from "./business/Business";
import Owner from "./owner/Owner";
import Review from "./review/Review";
import Submitted from "./submit/Submitted";

const steps = ["Business", "Owner", "Review"];

export default function HorizontalStepperWithError() {
  //
  //
  const [activeStep, setActiveStep] = React.useState(0);
  const [errorStep, setErrorStep] = React.useState(-1);
  const [submitted, setSubmitted] = React.useState(false);

  const isStepFailed = (index: number) => {
    return errorStep === index;
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setSubmitted(true);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const setErrorIndex = (errorIndex: any) => {
    setErrorStep(errorIndex);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Business handleNext={handleNext} setErrorIndex={setErrorIndex} />
        );
      case 1:
        return (
          <Owner
            handleBack={handleBack}
            handleNext={handleNext}
            setErrorIndex={setErrorIndex}
          />
        );
      case 2:
        return (
          <Review
            handleBack={handleBack}
            handleNext={handleNext}
            setErrorIndex={setErrorIndex}
          />
        );
      default:
        throw new Error("Unknown step!");
    }
  };

  return (
    <Box sx={{ width: "100%" }} m={10}>
      {submitted ? (
        <Submitted />
      ) : (
        <>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const labelProps: {
                optional?: React.ReactNode;
                error?: boolean;
              } = {};
              if (isStepFailed(index)) {
                labelProps.optional = (
                  <Typography variant="caption" color="error" fontWeight={"bold"}>
                    Error
                  </Typography>
                );
                labelProps.error = true;
              }

              return (
                <Step
                  key={label}
                  style={{
                    fontSize: "large",
                    fontWeight: "bold",
                  }}
                >
                  <StepLabel {...labelProps}>{
                    <Typography fontWeight={"bold"} variant="h6">{label}</Typography>
                  }</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <React.Fragment>
            <Box sx={{ m: 7 }}>{getStepContent(activeStep)}</Box>
          </React.Fragment>
        </>
      )}
    </Box>
  );
}
