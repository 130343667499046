// import {
//   BarElement,
//   CategoryScale,
//   Chart as ChartJS,
//   Legend,
//   LinearScale,
//   Title,
//   Tooltip,
// } from "chart.js";
// import _ from "lodash";
// import { Bar } from "react-chartjs-2";
// import { formatCentToUsd } from "../transaction/Transaction";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const options = {

//   scales: {
//     y: {
//       title: {
//         display: true,
//         text: "Amount $",
//       },
//     },
//   },
//   y: {
//     min: 0,
//     max: 2500,
//     stepSize: 500,
//   },
//   responsive: true,
//   labels: {
//     position: "bottom",
//   },
//   plugins: {
//     datalabels: {
//       formatter: function (value: any, context: any) {
//         return formatCentToUsd(value * 100)
//       },
//       color: "white",
//     },
//     title: {
//       display: true,
//       text: "Monthly Payments",
//       position: "top" as const,
//     },
//     legend: {
//       position: "bottom" as const,
//       labels: {
//         boxWidth: 13
//       },
//     },
//   },
// };

// const labels = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December"
// ];

// export function FundChart(props: any) {
//   const data = {
//     labels,
//     datasets: [
//       {
//         label: "Payments",
//         data: labels.map((label) => {
//           let month;
//           switch (label) {
//             case "January": month = 1; break;
//             case "February": month = 2; break;
//             case "March": month = 3; break;
//             case "April": month = 4; break;
//             case "May": month = 5; break;
//             case "June": month = 6; break;
//             case "July": month = 7; break;
//             case "August": month = 8; break;
//             case "September": month = 9; break;
//             case "October": month = 10; break;
//             case "November": month = 11; break;
//             case "December": month = 12; break;
//             default:
//               break;
//           }
//           const charge = _.find(props.chargePerMonth, { month });
//           return charge?.amount / 100;
//         }),
//         backgroundColor: "#386fa4",
//       },
//     ],
//   };

//   return <Bar
//     options={options}
//     data={data}
//     height={"35%"}
//     width={"100%"}
//   />;
// }


import React, { PureComponent } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box } from "@mui/material";
import _ from "lodash";

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export function FundChart(props: any) {

  const data = labels.map((label) => {
    let month;
    switch (label) {
      case "January": month = 1; break;
      case "February": month = 2; break;
      case "March": month = 3; break;
      case "April": month = 4; break;
      case "May": month = 5; break;
      case "June": month = 6; break;
      case "July": month = 7; break;
      case "August": month = 8; break;
      case "September": month = 9; break;
      case "October": month = 10; break;
      case "November": month = 11; break;
      case "December": month = 12; break;
      default:
        break;
    }



    const charge = _.find(props.chargePerMonth, { month });
    return { name: label, Payments: charge?.amount / 100 };
  })

  const renderLegendText = (value: string) => {
    return <span style={{ color: "black", fontSize: "small" }}>{value}</span>;
  };

  return (
    <Box sx={{ width: "100%", height: { xs: 200, md: 300, lg: 400 }  }}>
      <ResponsiveContainer>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend formatter={renderLegendText} />
          <Bar dataKey="Payments" fill="#386fa4" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
