import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleIcon from "@mui/icons-material/People";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SettingsIcon from "@mui/icons-material/Settings";
import SpeedIcon from "@mui/icons-material/Speed";
import StartIcon from "@mui/icons-material/Start";
import { Grid } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../utils/AuthProvider";
import AccountMenu from "./AccountMenu";
import SidebarMenu from "./SidebarMenu";

export const navy = "#1b3b65";
export const orange = "#cd3f3f";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const getListItemsByRole = (role: any, onboardingStatus: any) => {

  let items: any = [];

  switch (role) {
    case "ADMIN":
      if (onboardingStatus === "COMPLETED") {
        items = [
          "Dashboard",
          "Users",
          "Cards",
          "Transactions",
          "Accounting",
          "Charges",
          "Banking",
          "Support",
          "Setting",
        ];
      } else {
        items = ["Onboard", "Support", "Setting"];
      }
      break;
    case "ACCOUNTANT":
      items = [
        "Dashboard",
        "Users",
        "Cards",
        "Transactions",
        "Accounting",
        "Charges",
        "Banking",
        "Support",
        "Setting",
      ];
      break;
    case "USER":
      items = ["Cards", "Transactions", "Support", "Setting"];
      break;
    default:
      break;
  }

  return items;
};

export default function MiniDrawer() {

  const { token, role, onboardingStatus }: any = React.useContext(AuthContext);
  const location = useLocation();


  const [listItems, setListItems] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  // const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {

    setListItems(getListItemsByRole(role, onboardingStatus));
    setOpen(false);

  }, [token, role, onboardingStatus]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const getIcon = (text: any) => {
    switch (text) {
      case "Onboard":
        return <StartIcon color="primary" />;
      case "Dashboard":
        return <SpeedIcon color="primary" />;
      case "Banking":
        return <AccountBalanceIcon color="primary" />;
      case "Accounting":
        return <PointOfSaleIcon color="primary" />
      case "Users":
        return <PeopleIcon color="primary" />;
      case "Cards":
        return <CreditCardIcon color="primary" />;
      case "Charges":
        return <AccountTreeIcon color="primary" />;
      case "Transactions":
        return <ReceiptLongIcon color="primary" />;
      case "Support":
        return <ContactSupportOutlinedIcon color="primary" />;
      case "Setting":
        return <SettingsIcon color="primary" />;
      default:
        throw new Error("Invalid Icon!")
    }
  };

  return (
    <>
      {
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Grid container>
              <Grid item xs={6}>
                <Toolbar style={{}}>
                  {token && (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={toggleDrawer}
                      edge="start"
                      sx={{
                        marginRight: 5,
                        display: { xs: 'none', md: open ? 'none' : 'flex' }
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}

                  {token && (
                    <Box sx={{ display: { xs: 'block', md: 'none' }, mr: 1 }}>
                      <Box display="flex" justifyContent="flex-end">
                        <SidebarMenu listItems={listItems} />
                      </Box>
                    </Box>
                  )}

                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    sx={{ fontWeight: "bold", letterSpacing: 2 }}
                  >
                    InfoTechCard
                  </Typography>
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ fontWeight: "bold", letterSpacing: 1.5 }}
                    color={orange}
                  >
                    .
                  </Typography>
                </Toolbar>
              </Grid>
              {token && (
                <Grid item xs={6} padding={1}>
                  <Box display="flex" justifyContent="flex-end">
                    <AccountMenu></AccountMenu>
                  </Box>
                </Grid>
              )}
            </Grid>
          </AppBar>

          {token && (
            <Drawer variant="permanent" sx={{ display: { xs: 'none', md: 'block' } }} open={open}>
              <DrawerHeader>
                <IconButton onClick={toggleDrawer}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                {listItems.map((text, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    component={Link}
                    to={"/" + text.toLowerCase()}
                    style={
                      // selectedIndex === index
                      location.pathname.includes(text.toLowerCase())
                        ? { borderRight: `2.5px solid ${navy}` }
                        : {}
                    }
                  >
                    <ListItemButton
                      style={{
                        textDecoration: "none",
                        border: "none",
                      }}
                      selected={location.pathname.includes(text.toLowerCase())}
                    >
                      <ListItemIcon sx={{ border: "", marginLeft: "5px" }}>
                        {getIcon(text)}
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          fontWeight: "bold",
                          color: "primary",
                          fontSize: "small",
                        }}
                        primary={text}
                        style={{ color: "black" }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          )}
          <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
            <DrawerHeader />
          </Box>
        </Box>
      }
    </>
  );
}
