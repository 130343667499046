import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Button, Grid, List, ListItemText, Typography } from "@mui/material";
import { filesize } from "filesize";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import instance from "../../services/AxiosInterceptor";
import { toast } from "react-toastify";

export function Dropzone(props: any) {
  //
  //

  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState("");

  const onDrop = useCallback(
    (acceptedFiles: any, fileRejections: any) => {
      //

      setFiles(files.concat(...acceptedFiles));

      fileRejections.forEach((file: any) => {
        file.errors.forEach((err: any) => {
          if (err.code === "file-too-large") {
            console.log(`Error: ${err.message}`);
            setErrors(
              "Max file size should be " +
                JSON.stringify(
                  filesize(10485760, { base: 2, standard: "jedec" })
                )
            );
          }

          if (err.code === "file-invalid-type") {
            console.log(`Error: ${err.message}`);
            setErrors("Acceptable file types are images, text, and pdf.");
          }

          if (err.code.length > 0) {
            console.error(`Error: ${err.message}`);
          }
        });
      });

      if (fileRejections.length > 0) return;

      let formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      formData.append("upload_uuid", props.upload_uuid);

      instance
        .post("/support/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {})
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {});
    },
    [files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 3,
    maxSize: 2097152 * 5,
    multiple: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "text/plain": [],
      "application/pdf": [],
    },
    onDrop,
  });

  return (
    <Grid container mt={5}>
      <Grid
        item
        {...getRootProps({
          className: "dropzone",
        })}
        xs={12}
      >
        <Button
          size="large"
          style={{ color: "gray", border: "1px solid gray" }}
          variant="outlined"
          component="label"
          onClick={() => setErrors("")}
          endIcon={<DriveFolderUploadIcon />}
        >
          Attach File
          <input
            id="file-upload"
            type="file"
            name="file"
            hidden
            {...getInputProps({})}
          />
        </Button>

        {errors.length !== 0 && (
          <Typography color="error" py={2}>
            {errors}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <List dense>
          {files.map((file: any, index) => (
            <div key={index}>
              <ListItemText>
                {file.path} -{" "}
                {filesize(file.size, { base: 2, standard: "jedec" })}
              </ListItemText>
            </div>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
