import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { AppBar, Avatar, Badge, Toolbar } from "@mui/material";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { green, orange } from "material-ui-colors";
import { toast } from "react-toastify";
import instance from "../../services/AxiosInterceptor";
import { pointer } from "../../styles";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function SyncTransactionConfirmationModal(props: any) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleSync(): void {
    instance.post("/accounting/sync", { "transaction_uuid": props.transaction_uuid })
      .then(() => {
        toast.success("Synced successfully.");
      })
      .catch((error: any) => {
        toast.error(error.message);
      })
      .finally(() => {
        setOpen(false);
      })
  }

  return (
    <>
      {
        props.synced ?
          <Badge color="success" overlap="circular"
          >
            <Avatar sx={{ bgcolor: green[500], width: 18, height: 18 }}>
              <Typography variant="caption" fontWeight={"bold"}>qb</Typography>
            </Avatar>
          </Badge>
          :
          <Badge color="success" overlap="circular"
            onClick={handleClickOpen}
            style={pointer}
          >
            <Avatar sx={{ bgcolor: orange[500], width: 18, height: 18 }}>
              <Typography variant="caption" fontWeight={"bold"}>qb</Typography>
            </Avatar>
          </Badge>
      }
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              Confirm
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Typography sx={{ p: 3 }}>
            Please confirm to sync the transaction on QuickBooks.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleSync}
            sx={{ m: 1.5 }}
          >
            sync
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
