import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import instance from "../../services/AxiosInterceptor";
import { Dropzone } from "./Dropzone";

function Support() {
  //
  const validationSchema = yup.object({
    title: yup.string().max(50).required("Title is required"),
    message: yup.string().max(2000).required("Message is required"),
  });

  const initialValues = {
    title: "",
    message: "",
    upload_uuid: uuidv4(),
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      instance
        .post("/support", {
          title: values.title,
          message: values.message,
          upload_uuid: values.upload_uuid,
        })
        .then((response) => {
          toast.success("Submitted!");
          resetForm({ values: initialValues });
          setSubmitted(true);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => { });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
  } = formik;

  const [submitted, setSubmitted] = useState(false);

  return (
    <>
      {submitted ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          m={10}
        >
          <Grid item justifyContent="center" alignItems="center">
            <Typography>
              Your message was submitted. We check it out and will back to you
              if needed! Thanks for your business!
            </Typography>
          </Grid>
        </Grid>
      ) : (
          <Container component="main">
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Card sx={{ width: { xs: '100%', md: "75%" } }}>
                <CardHeader
                  title="Support"
                  titleTypographyProps={{
                    textAlign: "center",
                    padding: 2,
                    fontSize: "20px",
                  }}
                  subheader="Any comments, concerns, or questions? Submit a support message
                  to us and we will back to you shortly."
                  subheaderTypographyProps={{ textAlign: "center" }}
                />
                <CardContent>
                  <FormikProvider value={formik}>
                    <Form
                      onSubmit={handleSubmit}
                      id="validation-forms"
                      noValidate
                    >
                      <Grid container>
                        <Grid item mt={5} xs>
                          <Stack spacing={0.5}>
                            <TextField
                              size="small"
                              variant="outlined"
                              id="title"
                              name="title"
                              label="Title"
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.title && Boolean(errors.title)}
                              helperText={
                                <Box
                                  component="span"
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>{touched.title && errors.title}</span>
                                  {
                                    <span>{`${values.title.length
                                      } / ${50}`}</span>
                                  }
                                </Box>
                              }
                              fullWidth
                            />
                          </Stack>
                        </Grid>

                        <Grid item xs={12} mt={5}>
                          <Stack spacing={0.5}>
                            <TextField
                              size="small"
                              variant="outlined"
                              id="message"
                              name="message"
                              label="Message"
                              value={values.message}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.message && Boolean(errors.message)}
                              multiline
                              rows={15}
                              helperText={
                                <Box
                                  component="span"
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>
                                    {touched.message && errors.message}
                                  </span>
                                  {
                                    <span>{`${values.message.length
                                      } / ${1028}`}</span>
                                  }
                                </Box>
                              }
                            />
                          </Stack>
                        </Grid>
                        <Dropzone upload_uuid={values.upload_uuid} />
                      </Grid>

                      <Grid item xs={12} my={3}>
                        <Divider />
                        <Stack direction="row" justifyContent="flex-end">
                          <Button
                            size="small"
                            variant="contained"
                            sx={{ my: 3, ml: 1 }}
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Stack>
                      </Grid>
                    </Form>
                  </FormikProvider>
                </CardContent>
              </Card>
            </Box>
          </Container>
      )}
    </>
  );
}

export default Support;
