import axios from "axios";
import { createContext, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }: any) => {

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [onboardingStatus, setOnboardingStatus] = useState(localStorage.getItem("onboarding_status"));
  const [businessUuid, setBusinessUuid] = useState(localStorage.getItem("business_uuid"));

  const navigate = useNavigate();

  const login = useCallback(async ({ email, password }: any) => {

    await axios
      .post(process.env.REACT_APP_ITC_BASE_API_URL + "/auth/signin", {
        email: email,
        password: password,
      })
      .then((res) => {

        setToken(res.data.token)
        setRole(res.data?.role)
        setOnboardingStatus(res.data.onboarding_status)
        setBusinessUuid(res.data.business_uuid)

        localStorage.setItem("token", res.data.token)
        localStorage.setItem("role", res.data.role)
        localStorage.setItem("business_uuid", res.data.business_uuid)
        localStorage.setItem("onboarding_status", res.data.onboarding_status)

        if (
          res.data?.role === "ADMIN" &&
          res.data?.onboarding_status !== "COMPLETED"
        ) {
          navigate("/onboard/welcome");
        } else {
          navigate("/dashboard");
        }

      })
      .catch((err) => {
        console.error(err)
        toast.error(err.response?.data.message || "Unable to Sign in.");
      });
  }, [navigate])

  const logout = useCallback(() => {

    localStorage.removeItem("token")
    localStorage.removeItem("role")
    localStorage.removeItem("business_uuid")
    localStorage.removeItem("onboarding_status")
    localStorage.removeItem("link_token")

    setToken(null)
    navigate("/signin");
  }, [navigate])

  const value =
    useMemo(
      () => ({
        login,
        logout,
        token,
        role,
        onboardingStatus,
        businessUuid,
      }),
      [token, businessUuid, onboardingStatus, role, logout, login]
    );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

