import { Chip, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import moment from 'moment';
import React, { useEffect } from 'react';
import { toast } from "react-toastify";
import BackDrop from "../components/BackDrop";
import { navy } from "../components/menu/Drawer";
import EmptyTable from "../components/onboard/owner/EmptyTable";
import instance from "../services/AxiosInterceptor";
import { AuthContext } from "../utils/AuthProvider";
import AccountingDialog from "./AccountingDialog";
import DeleteAccountingModal from "./DeleteAccountingModal";

function Integration() {

  const { businessUuid }: any = React.useContext(AuthContext);

  interface Column {
    id:
    | "name"
    | "status"
    | "createdAt"
    | "action";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    {
      id: "name",
      label: "Accounting Software",
      minWidth: 25,
    },
    { id: "status", label: "Status", minWidth: 50 },
    { id: "createdAt", label: "Created At", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const fetchAccounting = () => {
    setFetchingData(true)
    instance
      .get("/accounting?businessUuid=" + businessUuid)
      .then((data: any) => {
        setAccountingSwData(data.data)
      })
      .catch((error) => {
        toast.error(error.response);
      })
      .finally(() => {
        setFetchingData(false);
      });
  }

  useEffect(() => {
    fetchAccounting()
  }, []);

  const [fetchingData, setFetchingData] = React.useState(true);
  const [accountingSwData, setAccountingSwData] = React.useState<any>(null);

  return (
    <>
      <BackDrop open={fetchingData} />
      <Grid container py={5}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <Typography
            fontWeight={"bold"}
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            px={2}
            py={0.75}
            border={"solid 0.5px silver"}
            bgcolor={"rgba(27,59,101, .1)"}
            color={navy}
          >
            Integrated Accounting
          </Typography>
          <Divider />

          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">

              <TableHead>
                <TableRow style={{}}>
                  {
                    accountingSwData?.created_at === null ?
                      <TableCell align="right" style={{}} size="small" colSpan={10}>
                        <AccountingDialog fetchAccounting={fetchAccounting}></AccountingDialog>
                      </TableCell> : ""
                  }
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      size="small"
                      sortDirection={false}
                      key={column.id}
                      align={column.label === 'Action' ? 'right' : column.align}
                      sx={{
                        minWidth: column.minWidth,
                        fontVariant: "h4",
                        fontWeight: "bold",
                        display: { xs: column.label == 'Status' ? 'none' : 'table-cell', md: 'table-cell' },   
                         p: { xs: '6px', sm: '6px 16px' }
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  accountingSwData?.created_at !== null ?
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={accountingSwData?.accounting_uuid}
                      >
                        <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' }}} className="text-overflow-table"  key="name" size="small">
                          <Typography variant="body2">{"QuickBooks"}</Typography>
                        </TableCell>

                        <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' }, display: { xs: 'none', sm: 'table-cell' } }} key="status" size="small" >

                          <Chip
                            color="success"
                            label="CONNECTED"
                            size="small"
                            variant="outlined"
                            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold", paddingBottom: "2px" }}
                            sx={{ height: "20px", paddingTop: "3px" }}
                          />

                        </TableCell>

                        <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' }}} key="createdAt" size="small">
                          <Typography variant="subtitle2">
                            {moment(accountingSwData?.created_at).format("YYYY-MM-DD HH:mm z")}
                          </Typography>
                        </TableCell>

                        <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' }}} align="right" size="small">
                          <DeleteAccountingModal accountingUuid={accountingSwData?.accounting_uuid} fetchAccounting={fetchAccounting} />
                        </TableCell>

                      </TableRow>
                     : <EmptyTable message="No accounting software integrated yet!"></EmptyTable>
                }

              </TableBody>
            </Table>
          </TableContainer>

        </Paper>
      </Grid >
    </>
  )
}
export default Integration;
