import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";

import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";

const InitiateForgotPassword = () => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      {submitted ? (
        <Typography m={5} p={3} variant="body1">
          We just sent an email to set your password. Please check out your
          inbox and move forward to reset your password.
        </Typography>
      ) : (
        <>
          <Typography variant="h6" m={5}>
            Reset Password
          </Typography>
          <Typography>
            Please provide the email address that you use to sign in to the
            application
          </Typography>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required("Email is required"),
            })}
            onSubmit={async (
              values,
              { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
              try {
                axios
                  .post(
                    process.env.REACT_APP_ITC_BASE_API_URL +
                    "/users/initiate-forgot-password",
                    {
                      email: values.email,
                    }
                  )
                  .then((response) => {
                    setSubmitted(true);
                  })
                  .catch((error) => {
                    toast.error(error.message);
                  });
              } catch (err: any) {
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid item xs={12} py={3}>
                  <Stack spacing={0.5}>
                    <TextField
                      size="small"
                      variant="outlined"
                      id="email"
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      autoComplete="off"
                      fullWidth
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Button
                      disabled={
                        isSubmitting || Object.keys(errors).length !== 0
                      }
                      type="submit"
                      variant="contained"
                      size="small"
                    >
                      submit
                    </Button>
                  </Stack>
                </Grid>
              </form>
            )}
          </Formik>
        </>
      )}
    </Grid>
  );
};

export default InitiateForgotPassword;
