import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { GenericCardProps } from "./GenericCardProps";

interface HoverSocialCardProps extends GenericCardProps {}

const HoverSocialCard = ({
  primary,
  secondary,
  iconPrimary,
  color,
}: HoverSocialCardProps) => {
  const IconPrimary = iconPrimary!;
  const primaryIcon = iconPrimary ? <IconPrimary /> : null;

  return (
    <Card
      elevation={0}
      sx={{
        background: color,
        position: "relative",
        color: "#fff",
        "&:hover svg": {
          opacity: 1,
          transform: "scale(1.1)",
        },
      }}
    >
      <CardContent>
        <Box
          mt={6}
          sx={{
            position: "absolute",
            right: 20,
            color: "#fff",
            "& svg": {
              width: 36,
              height: 36,
              opacity: 0.5,
              transition: "all .3s ease-in-out",
            },
          }}
        >
          {primaryIcon}
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={12} ml={1} mt={1}>
            <Typography variant="h4" letterSpacing={0}>
              {secondary}
            </Typography>
          </Grid>
          <Grid item xs={12} ml={1.2}>
            <Typography variant="subtitle2" color="inherit" fontWeight={"bold"} letterSpacing={2} mt={1}>
              {primary}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HoverSocialCard;
