import { Chip, Divider, Grid, TableSortLabel, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import * as React from "react";
import { toast } from "react-toastify";
import instance from "../../services/AxiosInterceptor";
import BackDrop from "../BackDrop";
import { navy } from "../menu/Drawer";
import EmptyTable from "../onboard/owner/EmptyTable";
import AddUserModal from "./AddUserModal";
import DeleteUserModal from "./DeleteUserModal";
import EditUserModal from "./EditUserModal";
import SearchUser from "./SearchUser";
import ViewUserModal from "./ViewUserModal";
interface Column {
  id: "name" | "email" | "role" | "status" | "createdAt" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: "name",
    label: "Name",
    minWidth: 100,
  },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "role", label: "Role", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "createdAt", label: "Created At", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

export interface UserData {
  user_uuid: string;
  name: string;
  email: string;
  role: string;
  status: string;
  created_at: string;
}

export interface SpendData {
  spend_limit: number;
  spend_limit_duration: string;
  spent: number;
}

export default function Users() {
  const [users, setUsers] = React.useState([]);
  const [totalElements, setTotalElements] = React.useState(0);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  type Order = "asc" | "desc";
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");

  const [search, setSearch] = React.useState("");
  const [searchBy, setSearchBy] = React.useState("name");

  const [backdrop, setBackdrop] = React.useState(true);

  const [loggedInUserRole, setLoggedInUserRole] = React.useState<any>(null);

  const fetchUsers = React.useCallback(
    () => {

      setBackdrop(true);

      instance
        .get("/users", {
          params: {
            page: page,
            size: rowsPerPage,
            order: order,
            sort: orderBy,
            [searchBy]: search,
          },
        })
        .then((response: any) => response.data)
        .then((data: any) => {

          const newPage = page;
          setPage(newPage);

          setUsers(data.content);
          setTotalElements(data.totalElements);
        })
        .catch((error: any) => {
          toast.error(error.response);
        })
        .finally(() => {
          setBackdrop(false);
        });
    }, [order, orderBy, page, rowsPerPage, search, searchBy]
  )

  React.useEffect(() => {

    fetchUsers();
    setLoggedInUserRole(localStorage.getItem("role"))

  }, [loggedInUserRole, fetchUsers]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const transformValue = (value: any) => {
    switch (value) {
      case "ACTIVE":
        return (
          <Chip
            color="success"
            label={value}
            variant="outlined"
            size="small"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "1.5px" }}
          />
        );
      case "INVITED":
        return (
          <Chip
            color="warning"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "1.5px" }}
          />
        );
      case "SUSPENDED":
        return (
          <Chip
            color="secondary"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "1.5px" }}
          />
        );
      case "TERMINATED":
        return (
          <Chip
            color="error"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "1.5px" }}
          />
        );
      default:
        return (
          <Chip
            color="error"
            label="Unknown"
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "1.5px" }}
          />
        );
    }
  };

  const setSearchCriteria = (search: any, searchBy: any) => {
    setSearch(search);
    setSearchBy(searchBy);
  };

  return (
    <>
      {backdrop ? (
        <BackDrop open={backdrop} />
      ) : (
        <Grid container py={10}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Typography
              fontWeight={"bold"}
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
              px={2}
              py={0.75}
              border={"solid 0.5px silver"}
              bgcolor={"rgba(27,59,101, .1)"}
              color={navy}
            >
              Users
            </Typography>
            <Divider />
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow sx={{ display: { xs: 'table-row', sm: 'none' } }}>
                    <TableCell align="left" colSpan={6} size="small">
                      <SearchUser setSearchCriteria={setSearchCriteria} />
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ display: { xs: 'table-row', sm: 'none' } }}>
                    <TableCell align="left" colSpan={6} size="small">
                      <AddUserModal fetchUsers={fetchUsers} />
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ display: { xs: 'none', sm: 'table-row' } }}>
                    <TableCell align="left" colSpan={2} size="small">
                      <SearchUser setSearchCriteria={setSearchCriteria} />
                    </TableCell>
                    <TableCell align="right" colSpan={4} size="small">
                      <AddUserModal fetchUsers={fetchUsers} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {users.length === 0 ? (
                      <EmptyTable message="No data found!" />
                    ) : (
                      <>
                        {columns.map((column) => (
                          <TableCell
                            size="small"
                            sortDirection={false}
                            key={column.id}
                            align={column.label === 'Action' ? 'right' : column.align}
                            sx={{
                              minWidth: column.minWidth,
                              fontVariant: "h4",
                              fontWeight: "bold",
                              backgroundColor: "",
                              display: { xs: column.label === 'Role' || column.label === 'Status' || column.label === 'Created At' ? 'none' : 'table-cell', md: 'table-cell' },
                            }}
                          >
                            {column.label}
                            {column.id !== "action" && (
                              <TableSortLabel
                                sx={{
                                  "& .MuiTableSortLabel-icon": {
                                    color: "black !important",
                                  },
                                }}
                                active={column.id === orderBy}
                                direction={order}
                                onClick={(event) => {
                                  if (column.id === orderBy) {
                                    order === "asc"
                                      ? setOrder("desc")
                                      : setOrder("asc");
                                  }
                                  setOrderBy(column.id);
                                }}
                              ></TableSortLabel>
                            )}
                          </TableCell>
                        ))}
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row: UserData) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.user_uuid}
                      >
                        <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} className="text-overflow-table" size="small" key="name"  >
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} className="text-overflow-table" size="small" key="email">
                          {row.email}
                        </TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} size="small" key="role">
                          {row.role}
                        </TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} size="small" key="status">
                          {transformValue(row.status)}
                        </TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} size="small" key="createdAt">
                          <Typography variant="subtitle2">
                            {moment(row.created_at).format(
                              "YYYY-MM-DD HH:mm z"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell size="small" align="right" sx={{ minWidth: '100px', p: { xs: '6px', sm: '6px 16px' } }}>
                          <ViewUserModal user={row} />
                          {
                            loggedInUserRole === "ADMIN" ?
                              <>
                                <EditUserModal user={row} fetchUsers={fetchUsers} />
                                <DeleteUserModal user={row} fetchUsers={fetchUsers} />
                              </> : ""
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
    </>
  );
}
