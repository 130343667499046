import { useContext } from "react";
import {  Outlet } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

export const ProtectedRoute = () => {
  const { token }: any = useContext(AuthContext);

  if (!token) {
    // return <Navigate to="/signin" />;
  }

  return <Outlet />;
};