import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import * as yup from "yup";
import { OwnerData } from "../../../models/OwnerData";
import OwnerList from "./OwnersTable";
import instance from "../../../services/AxiosInterceptor";
import BackDrop from "../../BackDrop";

interface OwnerFormProps {
  handleBack: () => void;
  handleNext: () => void;
  setErrorIndex: (i: number | null) => void;
}

function Owner({ handleBack, handleNext, setErrorIndex }: OwnerFormProps) {
  //

  const [backdrop, setBackdrop] = useState(false);
  const [ownerList, setOwnerList] = useState<any[]>([]);

  const validationSchema = yup.object({
    firstName: yup.string().max(255).required("First Name is required"),
    middleName: yup.string().max(255),
    lastName: yup.string().max(255).required("Last Name is required"),
    dob: yup.string().nullable().required("Date of birth is required."),
    phone: yup.string().required("Phone field is required"),
    phoneType: yup.string().required("Phone Type field is required"),
    phoneCode: yup.string().required("Phone Code field is required"),
    phoneExt: yup.string(),
    email: yup.string().required("Email field is required"),
    title: yup.string().required("Title field is required"),
    ssn: yup.string().required("SSN is required"),
    address1: yup.string().required("Address 1 is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    country: yup.string().required("Country is required"),
    zip: yup.string().min(5).max(10).required("Zip Code is required"),
    ownershipPercentage: yup
      .number()
      .min(1)
      .max(100)
      .required("Ownership Percentage is required"),
  });

  const initialValues = {
    ownerUuid: "",
    firstName: "",
    middleName: "",
    lastName: "",
    name: "",
    dob: null,
    phone: "",
    phoneType: "",
    phoneCode: "",
    phoneExt: "",
    email: "",
    title: "",
    ssn: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    ownershipPercentage: "",
  };

  const fetchOwners = () => {
    setBackdrop(true);
    instance
      .get("/onboard/owners")
      .then((response) => response.data)
      .then((data: OwnerData[]) => {
        setErrorIndex(null);
        setOwnerList(data);
        resetForm({ values: initialValues });
      })
      .catch((error) => {
        toast.error(error.message);
        setErrorIndex(1);
      })
      .finally(() => {
        setBackdrop(false);
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      //
      setBackdrop(true);

      const request: OwnerData = {
        ownerUuid: values.ownerUuid,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        name: values.name,
        dob: values.dob,
        email: values.email,
        title: values.title,
        phone: values.phone.replaceAll(" ", ""),
        phoneType: values.phoneType,
        phoneCode: values.phoneCode,
        phoneExt: values.phoneExt,
        ssn: values.ssn.replaceAll(" ", ""),
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        zip: values.zip,
        ownershipPercentage: values.ownershipPercentage,
      };

      instance
        .post("onboard/owner", request)
        .then((response) => {
          setErrorIndex(null);

          resetForm({ values: initialValues });
          fetchOwners();

          let message = request.ownerUuid
            ? "Owner updated successfully"
            : "Owner added successfully";
          toast.success(message);
        })
        .catch((error) => {
          toast.error(error.message);
          setErrorIndex(1);
        })
        .finally(() => {
          setBackdrop(false);
        });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
  } = formik;

  useEffect(() => {
    fetchOwners();
  }, []);

  function handleFetchOwner(uuid: string) {
    //
    setBackdrop(true);
    resetForm();

    instance
      .get("/onboard/owner?uuid=" + uuid)
      .then((response) => response.data)
      .then((data: OwnerData) => {
        //
        setFieldValue("ownerUuid", data.ownerUuid);
        setFieldValue("firstName", data.firstName);
        setFieldValue("middleName", data.middleName);
        setFieldValue("lastName", data.lastName);
        setFieldValue("ssn", data.ssn);
        setFieldValue("title", data.title);
        setFieldValue("dob", data.dob);
        setFieldValue("email", data.email);
        setFieldValue("title", data.title);
        setFieldValue("phone", data.phone);
        setFieldValue("phoneType", data.phoneType);
        setFieldValue("phoneCode", data.phoneCode);
        setFieldValue("phoneExt", data.phoneExt);
        setFieldValue("address1", data.address1);
        setFieldValue("address2", data.address2);
        setFieldValue("city", data.city);
        setFieldValue("state", data.state);
        setFieldValue("country", data.country);
        setFieldValue("zip", data.zip);
        setFieldValue("ownershipPercentage", data.ownershipPercentage);

        setErrorIndex(null);

        window.scrollTo(0, 0);

        toast.info("Owner is loaded and ready to edit");
      })
      .catch((error) => {
        toast.error(error.message);
        setErrorIndex(1);
      })
      .finally(() => {
        setBackdrop(false);
      });
  }

  function handleDeleteOwner(uuid: string) {
    setBackdrop(true);
    instance
      .delete("/onboard/owner?uuid=" + uuid)
      .then((response) => response.data)
      .then((data: OwnerData) => {
        setErrorIndex(null);
        fetchOwners();
        toast.success("Owner deleted successfully.");
      })
      .catch((error) => {
        toast.error(error.message);
        setErrorIndex(1);
      })
      .finally(() => {
        setBackdrop(false);
      });
  }

  return (
    <>
      <BackDrop open={backdrop} />
      {!backdrop && (
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit} id="validation-forms" noValidate>
            <Grid container spacing={3}>

              <Grid item xs={12} sm={6} sx={{ display: "none" }}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="owner-uuid"
                    name="ownerUuid"
                    value={values.ownerUuid}
                    disabled
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} mt={3}>
                <Typography variant="body1" fontWeight={"bold"} color={""}>Owner Details</Typography>
                <Divider />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel
                      error={touched.title && Boolean(errors.title)}
                      id="title-select-helper-label"
                    >
                      Title
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="title-select-helper-label"
                      id="title-select-helper"
                      name="title"
                      value={values.title}
                      label="Title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.title && Boolean(errors.title)}
                    >
                      <MenuItem value="">
                        <em>Select title</em>
                      </MenuItem>
                      <MenuItem value="CEO">Chief Executive Officer</MenuItem>
                      <MenuItem value="CFO">Chief Financial Officer</MenuItem>
                      <MenuItem value="COO">Chief Operating Officer</MenuItem>
                      <MenuItem value="OWNER">Owner</MenuItem>
                      <MenuItem value="PRESIDENT">President</MenuItem>
                      <MenuItem value="TREASURER">Treasurer</MenuItem>
                      <MenuItem value="VICE_PRESIDENT">Vice President</MenuItem>
                    </Select>
                    {touched.title && Boolean(errors.title) && (
                      <FormHelperText error>{errors.title}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="first-name"
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    fullWidth
                    autoComplete="given-name"
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="middle-name"
                    name="middleName"
                    label="Middle Name"
                    value={values.middleName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.middleName && Boolean(errors.middleName)}
                    helperText={touched.middleName && errors.middleName}
                    fullWidth
                    autoComplete="middle-name"
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="last-name"
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Stack>
              </Grid>

              <Grid item xs={6} sm={3}>
                <Stack spacing={0}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Birth"
                      format="YYYY-MM-DD"
                      value={
                        values.dob !== null
                          ? dayjs(values.dob, "YYYY-MM-DD")
                          : null
                      }
                      onChange={(newValue) => setFieldValue("dob", newValue)}
                      slotProps={{
                        textField: {
                          style: { marginTop: "0px" },
                          size: "small",
                          name: "dob",
                          helperText: touched.dob && errors.dob,
                          error: touched.dob && Boolean(errors.dob),
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5} paddingTop="0px">
                  <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    id="ownership-percentage"
                    name="ownershipPercentage"
                    label="Ownership Percentage"
                    value={values.ownershipPercentage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      inputProps: {
                        max: 100,
                        min: 1,
                      },
                    }}
                    error={
                      touched.ownershipPercentage &&
                      Boolean(errors.ownershipPercentage)
                    }
                    helperText={
                      touched.ownershipPercentage &&
                      errors.ownershipPercentage
                    }
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <PatternFormat
                    size="small"
                    customInput={TextField}
                    mask="_"
                    format="### ## ####"
                    id="ssn"
                    name="ssn"
                    label="SSN"
                    placeholder="000 00 0000"
                    value={_.replace(values.ssn, " ", "")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ssn && Boolean(errors.ssn)}
                    helperText={touched.ssn && errors.ssn}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} mt={3}>
                <Typography fontWeight={"bold"} color={""}>Phone Details</Typography>
                <Divider />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel
                      error={touched.phoneType && Boolean(errors.phoneType)}
                      id="phoneType-select-helper-label"
                    >
                      Phone Type
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="phoneType-select-helper-label"
                      id="phoneType-select-helper"
                      name="phoneType"
                      value={values.phoneType}
                      label="Phone Type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phoneType && Boolean(errors.phoneType)}
                    >
                      <MenuItem value="">
                        <em>Select phone type</em>
                      </MenuItem>
                      <MenuItem value="HOME">Home</MenuItem>
                      <MenuItem value="MOBILE">Mobile</MenuItem>
                      <MenuItem value="WORK">Work</MenuItem>
                    </Select>
                    {touched.phoneType && Boolean(errors.phoneType) && (
                      <FormHelperText error>{errors.phoneType}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel
                      error={touched.phoneCode && Boolean(errors.phoneCode)}
                      id="phoneCode-select-helper-label"
                    >
                      Phone Code
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="phoneCode-select-helper-label"
                      id="phoneCode-select-helper"
                      name="phoneCode"
                      value={values.phoneCode}
                      label="Phone Code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phoneCode && Boolean(errors.phoneCode)}
                    >
                      <MenuItem value="">
                        <em>Select phone code</em>
                      </MenuItem>
                      <MenuItem value="1">+1</MenuItem>
                    </Select>
                    {touched.phoneCode && Boolean(errors.phoneCode) && (
                      <FormHelperText error>{errors.phoneCode}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <PatternFormat
                    size="small"
                    customInput={TextField}
                    format="### ### ####"
                    mask="_"
                    id="phone"
                    name="phone"
                    label="Phone"
                    placeholder="123 456 7890"
                    value={_.replace(values.phone, " ", "")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="phone-ext"
                    name="phoneExt"
                    label="Phone Ext."
                    value={values.phoneExt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phoneExt && Boolean(errors.phoneExt)}
                    helperText={touched.phoneExt && errors.phoneExt}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} mt={3}>
                <Typography fontWeight={"bold"} color={""}>Address Details</Typography>
                <Divider />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="address1"
                    name="address1"
                    label="Address 1"
                    placeholder="Address line 1"
                    value={values.address1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.address1 && Boolean(errors.address1)}
                    helperText={touched.address1 && errors.address1}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="address2"
                    label="Address 2"
                    name="address2"
                    placeholder="Address line 2"
                    value={values.address2}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="city"
                    name="city"
                    label="City"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel
                      error={touched.state && Boolean(errors.state)}
                      id="state-select-helper-label"
                    >
                      State
                    </InputLabel>
                    <Select
                      labelId="state-select-helper-label"
                      id="state-select-helper"
                      name="state"
                      value={values.state}
                      label="State"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.state && Boolean(errors.state)}
                    >
                      <MenuItem value="">
                        <em>Select state</em>
                      </MenuItem>
                      <MenuItem value="AL">Alabama</MenuItem>
                      <MenuItem value="AK">Alaska</MenuItem>
                      <MenuItem value="AZ">Arizona</MenuItem>
                      <MenuItem value="AR">Arkansas</MenuItem>
                      <MenuItem value="CA">California</MenuItem>
                      <MenuItem value="CO">Colorado</MenuItem>
                      <MenuItem value="CT">Connecticut</MenuItem>
                      <MenuItem value="DE">Delaware</MenuItem>
                      <MenuItem value="DC">District Of Columbia</MenuItem>
                      <MenuItem value="FL">Florida</MenuItem>
                      <MenuItem value="GA">Georgia</MenuItem>
                      <MenuItem value="HI">Hawaii</MenuItem>
                      <MenuItem value="ID">Idaho</MenuItem>
                      <MenuItem value="IL">Illinois</MenuItem>
                      <MenuItem value="IN">Indiana</MenuItem>
                      <MenuItem value="IA">Iowa</MenuItem>
                      <MenuItem value="KS">Kansas</MenuItem>
                      <MenuItem value="KY">Kentucky</MenuItem>
                      <MenuItem value="LA">Louisiana</MenuItem>
                      <MenuItem value="ME">Maine</MenuItem>
                      <MenuItem value="MD">Maryland</MenuItem>
                      <MenuItem value="MA">Massachusetts</MenuItem>
                      <MenuItem value="MI">Michigan</MenuItem>
                      <MenuItem value="MN">Minnesota</MenuItem>
                      <MenuItem value="MS">Mississippi</MenuItem>
                      <MenuItem value="MO">Missouri</MenuItem>
                      <MenuItem value="MT">Montana</MenuItem>
                      <MenuItem value="NE">Nebraska</MenuItem>
                      <MenuItem value="NV">Nevada</MenuItem>
                      <MenuItem value="NH">New Hampshire</MenuItem>
                      <MenuItem value="NJ">New Jersey</MenuItem>
                      <MenuItem value="NM">New Mexico</MenuItem>
                      <MenuItem value="NY">New York</MenuItem>
                      <MenuItem value="NC">North Carolina</MenuItem>
                      <MenuItem value="ND">North Dakota</MenuItem>
                      <MenuItem value="OH">Ohio</MenuItem>
                      <MenuItem value="OK">Oklahoma</MenuItem>
                      <MenuItem value="OR">Oregon</MenuItem>
                      <MenuItem value="PA">Pennsylvania</MenuItem>
                      <MenuItem value="RI">Rhode Island</MenuItem>
                      <MenuItem value="SC">South Carolina</MenuItem>
                      <MenuItem value="SD">South Dakota</MenuItem>
                      <MenuItem value="TN">Tennessee</MenuItem>
                      <MenuItem value="TX">Texas</MenuItem>
                      <MenuItem value="UT">Utah</MenuItem>
                      <MenuItem value="VT">Vermont</MenuItem>
                      <MenuItem value="VA">Virginia</MenuItem>
                      <MenuItem value="WA">Washington</MenuItem>
                      <MenuItem value="WV">West Virginia</MenuItem>
                      <MenuItem value="WI">Wisconsin</MenuItem>
                      <MenuItem value="WY">Wyoming</MenuItem>
                    </Select>
                    {touched.state && Boolean(errors.state) && (
                      <FormHelperText error>{errors.state}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    label="Zip Code"
                    id="zip"
                    name="zip"
                    placeholder="Zip/Postal Code"
                    value={values.zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.zip && Boolean(errors.zip)}
                    helperText={touched.zip && errors.zip}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={0.5}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel
                      error={touched.country && Boolean(errors.country)}
                      id="country-select-helper-label"
                    >
                      Country
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="country-select-helper-label"
                      id="country-select-helper"
                      name="country"
                      value={values.country}
                      label="Country"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.country && Boolean(errors.country)}
                    >
                      <MenuItem value="">
                        <em>Select country</em>
                      </MenuItem>
                      <MenuItem value="USA">USA</MenuItem>
                    </Select>
                    {touched.country && Boolean(errors.country) && (
                      <FormHelperText error>{errors.country}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>

            </Grid>

            <Grid item xs={12} my={3}>
              <Divider />
              <Stack direction="row" justifyContent="right">
                <Button
                  size="small"
                  variant="contained"
                  sx={{ my: 3, ml: 1 }}
                  type="submit"
                >
                  Save
                </Button>
              </Stack>

              <Grid item xs={12} md={6}>
                {/* <Typography
                  sx={{ mt: 4, mb: 2 }}
                  variant="body1"
                  fontWeight={"bold"}
                  component="div"
                >

                </Typography> */}
                <Typography
                  sx={{ mt: 4, mb: 2 }}
                  fontWeight={"bold"}
                  color={""}>Owners
                </Typography>

                <OwnerList
                  owners={ownerList}
                  fetchOwner={(uuid: string) => handleFetchOwner(uuid)}
                  deleteOwner={(uuid: string) => handleDeleteOwner(uuid)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} my={3}>
              <Divider />
              <Stack direction="row" justifyContent="space-between">
                <Button
                  variant="outlined"
                  onClick={handleBack}
                  sx={{ my: 3, ml: 1 }}
                  startIcon={<ChevronLeft />}
                  size="small"
                >
                  Back
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ my: 3, ml: 1 }}
                  type="submit"
                  onClick={() => {
                    handleNext();
                  }}
                  endIcon={<ChevronRight fontSize="small" />}
                  disabled={ownerList.length === 0}
                >
                  Next
                </Button>
              </Stack>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}

export default Owner;
