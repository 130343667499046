// import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
// import _ from "lodash";
// import { Doughnut } from "react-chartjs-2";

// import ChartDataLabels from "chartjs-plugin-datalabels";

// ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

// export function UserStatusChart(props: any) {

//   const activeUserCount = _.find(props.userStatusCount, { status: "ACTIVE" });
//   const invitedUserCount = _.find(props.userStatusCount, { status: "INVITED" });
//   const terminatedUserCount = _.find(props.userStatusCount, { status: "TERMINATED" });

//   const options: any = {
//     cutout: 55,
//     plugins: {
//       datalabels: {
//         formatter: function (value: any, context: any) {
//           return value ? +value : "";
//         },
//         color: "white",
//         font: {
//           weight: "bold",
//           size: "15px"
//         },
//       },
//       tooltip: {
//         enabled: false
//       },
//       legend: {
//         position: "bottom",
//         labels: {
//           boxWidth: 13
//         }
//       },
//       title: {
//         display: true,
//         text: `Users Status`,
//         font: {
//           weight: "bold",
//           size: "13px"
//         },
//       }
//     }
//   };

//   const data = {
//     responsive: true,
//     labels: ["Active", "Invited", "Terminated"],
//     legend: {},
//     datasets: [
//       {
//         label: "# of Users",
//         data: [
//           activeUserCount?.count,
//           invitedUserCount?.count,
//           terminatedUserCount?.count],
//         backgroundColor: [
//           "green",
//           "orange",
//           "red",
//         ],
//         borderColor: [
//           "silver",
//         ],
//         borderWidth: 1,
//       },
//     ],
//   };

//   return <Doughnut
//     data={data}
//     options={options}
//     updateMode={'resize'}
//     />;
// }


import { Box } from '@mui/material';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer, Label } from 'recharts';
import _ from "lodash";
const COLORS = ['green', 'orange', 'red'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (
  { cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }
    : {
      cx: number;
      cy: number;
      midAngle: number;
      innerRadius: number;
      outerRadius: number;
      percent: number;
      index: number;
      value: any;
    }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="middle" fontWeight={"bold"}>
      {value}
    </text>
  );
};

export function UserStatusChart(props: any) {

  const activeUserCount = _.find(props.userStatusCount, { status: "ACTIVE" });
  const invitedUserCount = _.find(props.userStatusCount, { status: "INVITED" });
  const terminatedUserCount = _.find(props.userStatusCount, { status: "TERMINATED" });

  const data = [
    { name: 'Active', value: activeUserCount?.count },
    { name: 'Invited', value: invitedUserCount?.count },
    { name: 'Terminated', value: terminatedUserCount?.count },
  ]

  const renderLegendText = (value: string) => {
    return <span style={{ color: "black", fontSize: "small" }}>{value}</span>;
  };

  return (
    <Box sx={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            labelLine={false}
            label={renderCustomizedLabel}
            innerRadius={70}
            // fill="#8884d8"
            dataKey="value"
            name="pv of pages"
            style={{ outline: 'none' }}
          >
            <Label value="Users" position="center" fontWeight={"bold"} />
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend formatter={renderLegendText} />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}

