import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { formatCentToUsd } from "./Transaction";

export default function TransactionTable({ transaction }: any) {
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell size="small">Created</TableCell>
            <TableCell size="small">Type</TableCell>
            <TableCell size="small">Result</TableCell>
            <TableCell size="small">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
          {transaction?.events.map((event: any) => (
            <TableRow
              key={event.transaction_event_uuid}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" size="small">
                {moment(event.created).format("YYYY-MM-DD HH:mm z")}
              </TableCell>
              <TableCell size="small">{event.type}</TableCell>
              <TableCell size="small">{event.result}</TableCell>
              <TableCell size="small">{formatCentToUsd(event.amount)}</TableCell>
            </TableRow>
          ))}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
