import { AxiosError } from "axios";
import { toast } from "react-toastify";

export function ToastError(error: any) {
    console.error(error)
    if (error instanceof AxiosError) {
        return toast.error(error.response?.data.message || "Error occurred! Please contact support team.");
    } else {
        return toast.error("Error happened! Please contact support team.")
    }
}
