import { EditOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import {
  AppBar,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Toolbar,
} from "@mui/material";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { toast } from "react-toastify";
import { pointer } from "../../styles";
import { NumericFormat } from "react-number-format";
import instance from "../../services/AxiosInterceptor";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface EditCardModalProps {
  card: {
    card_uuid: string;
    name: string;
    type: string;
    status: string;
    description: string;
    spend: {
      spent: number;
      spend_limit: number;
      spend_limit_duration: string;
    };
  };
  fetchCards: () => void;
}
export default function EditCardModal({
  card,
  fetchCards,
}: EditCardModalProps) {
  //

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    formik.resetForm();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const initialValues = {
    description: card.description,
    spendLimit: card.spend.spend_limit,
    spendLimitDuration: card.spend.spend_limit_duration,
    status: card.status,
    name: card.name,
    type: card.type,
  };
  const validationSchema = yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    spendLimitDuration: yup.string().required(),
    spendLimit: yup.string().required(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      instance
        .put("/cards", {
          name: values.name,
          card_uuid: card.card_uuid,
          description: values.description,
          spend_limit_duration: values.spendLimitDuration,
          spend_limit: values.spendLimit,
          status: values.status,
        })
        .then((response) => response.data)
        .then((data) => {
          handleClose();
          toast.success("Card updated successfully!");
          fetchCards();
        })
        .catch((error) => {
          toast.error("Error!");
        });
    },
  });

  const { errors, touched, values, handleSubmit, handleChange, handleBlur } =
    formik;

  return (
    <>
      <EditOutlined
        sx={{ mr: 1 }}
        style={pointer}
        fontSize="small"
        color="secondary"
        onClick={handleClickOpen}
      />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        fullWidth
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
              Edit Card
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent dividers>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit} id="edit-card-form">
              <Grid container spacing={3} justifyContent="center" my={5} >
                <Grid item xs={12} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                  <TextField
                    fullWidth
                    margin="dense"
                    size="small"
                    variant="standard"
                    id="name"
                    name="name"
                    label="Card Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                  {" "}
                  <TextField
                    fullWidth
                    margin="dense"
                    size="small"
                    variant="standard"
                    id="description"
                    name="description"
                    label="Description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid container rowSpacing={3} item xs={12} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                  <Grid xs={12} md={5.5} item sx={{mr: {xs: 0, md: 1}}}>
                      <NumericFormat
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale
                        allowNegative={false}
                        allowLeadingZeros
                        thousandSeparator=","
                        customInput={TextField}
                        sx={{ width: '100%' }}
                        margin="dense"
                        size="small"
                        variant="standard"
                        id="spendLimit"
                        name="spendLimit"
                        label="Spend Limit ($)"
                        value={values.spendLimit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.spendLimit && Boolean(errors.spendLimit)}
                        helperText={touched.spendLimit && errors.spendLimit}
                      />
                  </Grid>
                  <Grid xs={12} md={6} item sx={{ml: {xs: 0, md: 1}}}>
                      <FormControl
                        size="small"
                        sx={{ width: '100%' }}
                        style={{
                          marginTop: "0.5rem",
                        }}
                      >
                        <InputLabel
                          id="spend-limit-duration-select-helper-label"
                          variant="standard"
                          error={
                            touched.spendLimitDuration &&
                            Boolean(errors.spendLimitDuration)
                          }
                        >
                          Spend Limit Duration
                        </InputLabel>
                        <Select
                          variant="standard"
                          margin="dense"
                          labelId="spend-limit-duration-select-helper-label"
                          id="spend-limit-duration-select-helper"
                          name="spendLimitDuration"
                          value={values.spendLimitDuration}
                          label="Spend Limit Duration"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.spendLimitDuration &&
                            Boolean(errors.spendLimitDuration)
                          }
                        >
                          <MenuItem value="">
                            <em>Select spend limit duration</em>
                          </MenuItem>
                          <MenuItem value="MONTHLY">Monthly</MenuItem>
                          <MenuItem value="ANNUALLY">Annually</MenuItem>
                          <MenuItem value="TRANSACTION">Transaction</MenuItem>
                          <MenuItem value="FOREVER">Forever</MenuItem>
                        </Select>
                        {touched.spendLimitDuration &&
                          Boolean(errors.spendLimitDuration) && (
                            <FormHelperText error>
                              {errors.spendLimitDuration}
                            </FormHelperText>
                          )}
                      </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12} mb={5} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                  <Stack spacing={0.5}>
                    <FormControl
                      size="small"
                      sx={{ width: 200 }}
                      style={{
                        marginTop: "0.5rem",
                      }}
                    >
                      <InputLabel
                        id="card-status-select-helper-label"
                        variant="standard"
                        error={touched.status && Boolean(errors.status)}
                      >
                        Status
                      </InputLabel>
                      <Select
                        variant="standard"
                        margin="dense"
                        labelId="card-status-select-helper-label"
                        id="card-status-select-helper"
                        name="status"
                        value={values.status}
                        label="Card Status"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.status && Boolean(errors.status)}
                      >
                        <MenuItem value="">
                          <em>Select status</em>
                        </MenuItem>
                        <MenuItem
                          value="OPEN"
                          disabled={values.status === "CLOSED"}
                        >
                          ACTIVE
                        </MenuItem>
                        <MenuItem
                          value="PAUSED"
                          disabled={values.status === "CLOSED"}
                        >
                          SUSPENDED
                        </MenuItem>

                        <MenuItem
                          value="CLOSED"
                          disabled={values.status === "CLOSED"}
                        >
                          TERMINATED
                        </MenuItem>
                      </Select>
                      {touched.spendLimitDuration &&
                        Boolean(errors.spendLimitDuration) && (
                          <FormHelperText error>
                            {errors.spendLimitDuration}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
              <DialogActions>
                <Button autoFocus type="submit" variant="outlined">
                  Submit
                </Button>
              </DialogActions>
            </Form>
          </FormikProvider>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
