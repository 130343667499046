import ReceiptIcon from "@mui/icons-material/Receipt";
import { Badge, Box, Chip, Collapse, Typography} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import * as React from "react";
import { toast } from "react-toastify";
import instance from "../../services/AxiosInterceptor";
import ReceiptDetailsTable from "./ReceiptDetailsTable";
import SyncTransactionConfirmationModal from "./SyncTransactionConfirmationModal";
import { formatCentToUsd } from "./Transaction";
import ViewTransactionModal from "./ViewTransactionModal";
function TransactionItem({ row }: any) {

  // const Item = styled(Paper)(({ theme }) => ({
  //   // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   // ...theme.typography.body2,
  //   // padding: theme.spacing(1),
  //   textAlign: 'center',
  //   // color: theme.palette.text.secondary,
  // }));

  const [open, setOpen] = React.useState(false);
  const [receipts, setReceipts] = React.useState([]);

  const transformTransactionStatusValue = (value: any) => {
    switch (value) {
      case "SETTLED":
        return (
          <Chip
            color="success"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "3px" }}
          />
        );
      case "PENDING":
        return (
          <Chip
            color="warning"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "3px" }}
          />
        );
      case "DECLINED":
        return (
          <Chip
            color="error"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "3px" }}
          />
        );
      case "VOIDED":
      case "EXPIRED":
        return (
          <Chip
            color="secondary"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "3px" }}
          />
        );
      default:
        return (
          <Chip
            color="error"
            label="Unknown"
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "3px" }}
          />
        );
    }
  };

  return (
    <>
      <TableRow
        key={row?.transaction_uuid}
      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} component="th" scope="row" size="small">
          <Typography variant="subtitle2">
            {moment(row?.created_at).format("YYYY-MM-DD HH:mm z")}
          </Typography>
        </TableCell>
        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} size="small">
          <Typography variant="subtitle2">
            {row.mcc !== null ? row.mcc?.category : "- -"}
          </Typography>
          <Typography variant="caption">
            {row.mcc !== null ? row.mcc?.description : "- -"}
          </Typography>
        </TableCell>
        <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} className="text-overflow-table" size="small">{formatCentToUsd(row?.amount)}</TableCell>
        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} size="small">{transformTransactionStatusValue(row?.status)}</TableCell>
        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} size="small">{row?.card.name}</TableCell>
        <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} className="text-overflow-table" size="small">
          <Typography>{row?.card.user.name}</Typography>
          <Typography variant="caption">{row?.card.user.email}</Typography>
        </TableCell>
        <TableCell align="right" size="small" sx={{ paddingTop: "1.2rem", p: { xs: '6px', sm: '6px 16px' } }}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
            <ViewTransactionModal transaction={row} />
            <Badge
              color="primary"
              sx={{ "& .MuiBadge-badge": { fontSize: 10, height: 15, minWidth: 15 } }}
            >
              <ReceiptIcon
                fontSize="small"
                color="action"
                onClick={() => {
                  setOpen(!open);
                  if (!open) {
                    instance
                      .get("/receipts?transaction_uuid=" + row.transaction_uuid)
                      .then((response) => {
                        setReceipts(response.data);
                      })
                      .catch((error) => {
                        toast.error(error.message);
                      })
                      .finally(() => { });
                  }
                }}
                sx={{ cursor: "pointer", p: 0 }}
              />
            </Badge>
            <Box ml={1}>
              <SyncTransactionConfirmationModal
                transaction_uuid={row.transaction_uuid}
                synced={row.synced}
              />
            </Box>
          </Box>

        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderTop: "none" }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding: {xs: 2, sm: 3, md: 5}}}>
              <Typography
                variant="body2"
                fontWeight={"bold"}
                gutterBottom
                component="div"
                paddingBottom={1}
              >
                Upload Receipt
              </Typography>
              <ReceiptDetailsTable
                transaction_uuid={row.transaction_uuid}
                receipts={receipts}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default TransactionItem;
