import { Grid } from "@mui/material";

function ESign() {
    return <>
        <Grid container>
            <p>This Electronic Communication Disclosure (“<strong>E-SIGN Consent</strong>”) applies to all communications and/or disclosures that Lithic, Inc., doing business as Privacy.com (“<strong>Privacy</strong>,” “<strong>we</strong>,” “<strong>our</strong>,” or “<strong>us</strong>”) is legally required to provide to you in writing in connection with your Account and any related products and services (“<strong>Communications</strong>”). This E-SIGN Consent supplements and is to be construed in accordance with Privacy’s Terms of Service. The words &quot;<strong>you</strong>&quot; and &quot;<strong>your</strong>&quot; mean the account holder. &quot;<strong>Account</strong>&quot; means the account you have with us.</p>
            <h2>1. Consent for Electronic Signatures, Records and Disclosures</h2>
            <p>Your consent to the electronic delivery of disclosures will be required before you can begin using your Account. If you are unable or unwilling to provide such consent, please do not enroll for an Account. Once you have activated your Account, you may withdraw your consent for E-SIGN and request paper communications and/or disclosures as noted below.</p>
            <p>We suggest you read this document and print a copy for your reference.</p>
            <h2>2. Scope of Communications to Be Provided in Electronic Form</h2>
            <p>When you use a Privacy product or service, you agree that we may provide you with any Communications in electronic format, and that we may discontinue sending paper Communications to you, unless and until you withdraw your consent as described below. Your consent to receive electronic communications and transactions includes, but is not limited to:</p>
            <ul role="list">
                <li>All legal and regulatory disclosures and communications associated with your Account and any related products and services</li>
                <li>Privacy’s Terms of Services and any notices about a change in the Terms of Service</li>
                <li>Privacy policies and notices</li>
                <li>Error resolution policies and notices</li>
                <li>Responses to claims filed in connection with your Account</li>
                <li>Notices regarding insufficient funds or negative balances</li>
            </ul>
            <h3>3. Method of Providing Communications to You in Electronic Form</h3>
            <p>All Communications that we provide to you in electronic form will be provided by access to our website or by email.</p>
            <h4>4. How to Update Your Records</h4>
            <p>It is your responsibility to provide us with a true, accurate and complete email address, your contact information, and other information related to this E-SIGN Consent and your Account, and to maintain and update promptly any changes in this information. You may update your information by logging into your Account and updating your profile.</p>
            <h4>5. Hardware and Software Requirements</h4>
            <p>To access, view, and retain electronic Communications that we make available to you, you must have the following software and hardware: A PC or Mac compatible computer or other device capable of accessing the Internet and an Internet Browser software program that supports at least 128 bit encryption. To read some documents, you may need a PDF file reader. If these requirements change while you have an active Account, and the change creates a material risk that you may not be able to receive Communications electronically, we will notify you of these changes. You will need a printer or a long-term storage device, such as your computer’s disk drive, to retain a copy of the E-Sign Consent for future reference.</p>
            <h4>6. Requesting Paper Copies</h4>
            <p>We will not send you a paper copy of any Communication unless you request it or we otherwise deem it appropriate to do so. You can obtain a paper copy of an electronic Communication by printing it yourself or by requesting that we mail you a paper copy, provided that such request is made within a reasonable time after we first provided the electronic Communication to you. To request a paper copy, contact us by mailing your request to 148 Lafayette St, Fl 3, NY, NY 10013.</p>
            <p>We reserve the right, but assume no obligation, to provide a paper (instead of electronic) copy of any Communication that you have authorized us to provide electronically. For the avoidance of doubt, requesting a paper copy of any Communication, in and of itself, will not be treated as withdrawal of consent to receive electronic Communications.</p>
            <h4>7. <strong>Communications in Writing</strong></h4>
            <p>All Communications in either electronic or paper format from us to you will be considered &quot;in writing.&quot; You should print or download for your records a copy of this E-Sign Consent and any other Communication that is important to you.</p>
            <h4>8. Federal Law</h4>
            <p>You acknowledge and agree that your consent to electronic Communications is being provided in connection with a transaction affecting interstate commerce that is subject to the federal Electronic Signatures in Global and National Commerce Act, and that you and we both intend that the Act apply to the fullest extent possible to validate our ability to conduct business with you by electronic means.</p>
            <h4>9. Termination/Changes</h4>
            <p>We reserve the right, in our sole discretion, to discontinue the provision of your electronic Communications, or to terminate or change the terms and conditions on which we provide electronic Communications. We will provide you with notice of any such termination or change as required by law.</p>
            <h4>10. How to Withdraw Consent</h4>
            <p>You may withdraw your consent to receive Communications in electronic form at any time by sending us a written request by mail to 148 Lafayette St, Fl 3, NY, NY 10013. If you withdraw your consent, your Account and all associated cards will be closed in accordance with the Terms of Service and we will send subsequent required communications and disclosures to you in writing to the most current address we have on file.</p>
            <p>We will not impose any fee to process the withdrawal of your consent to receive electronic Communications. Any withdrawal of your consent to receive electronic Communications will be effective only after we have a reasonable period of time to process your withdrawal. In the meantime, you will continue to receive Communications in electronic form. If you withdraw your consent, the legal validity and enforceability of prior Communications delivered in electronic form will not be affected.</p>
            <p><em>Last updated: January 30, 2023</em></p>
        </Grid>
    </>;
}

export default ESign;