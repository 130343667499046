import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AccountingDialog(props: any) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.fetchAccounting()
  };

  const [iframeState, setIframeState] = React.useState("");

  React.useEffect(() => {

    const src = process.env.REACT_APP_ITC_ACCOUNTING_API_URL +
      "/connectToQuickbooks?businessUuid="
      + localStorage.getItem("business_uuid");

    let iframe = `<iframe 
    frameborder="0" 
    style="position:fixed; width:100%; height:100%; border:none; margin:0; padding:0; "
    scrolling="no" 
    allowfullscreen="true" 
    src="` + src + `"></iframe>`

    setIframeState(iframe)
  })

  return (
    <div>
      <Button variant='contained' onClick={handleClickOpen} size='small'>
        Connect QuickBooks
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              InfoTechCard & QuickBooks Integration
            </Typography>
            <Button
              variant="outlined"
              size="small"
              color='inherit'
              onClick={handleClose}
              sx={{ m: 1.5 }}
            >
              Back to Accounting Page
            </Button>
          </Toolbar>
        </AppBar>
        <div dangerouslySetInnerHTML={{ __html: iframeState }} />
      </Dialog>
    </div>
  );
}
