import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Stack, TableCell, Typography } from "@mui/material";
function EmptyTable({ message }: any) {

  return (
      <TableCell colSpan={100}>
        <Stack direction="row" gap={1} justifyContent={"center"} m={1}>
          <ErrorOutlineIcon />
          <Typography variant="body1">{message}</Typography>
        </Stack>
      </TableCell>
  );
}

EmptyTable.defaultProps = {
  message: 'No data found!',
};

export default EmptyTable;
