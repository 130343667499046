import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import BusinessDetails from "../business/BusinessDetails";
import OwnersList from "../owner/OwnersList";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import CheckIcon from "@mui/icons-material/Check";
import { toast } from "react-toastify";
import instance from "../../../services/AxiosInterceptor";
import { useEffect, useState } from "react";
import { BusinessData } from "../../../models/BusinessData";
import { OwnerData } from "../../../models/OwnerData";
import BackDrop from "../../BackDrop";
interface ReviewProps {
  handleNext: () => void;
  handleBack: () => void;
  setErrorIndex: (i: number) => void;
}

function Review({ handleNext, handleBack, setErrorIndex }: ReviewProps) {
  //

  const [businessData, setBusinessData] = useState<BusinessData>();
  const [owners, setOwners] = useState<OwnerData[]>();

  const [loadingBusiness, setLoadingBusiness] = useState(true);
  const [loadingOwners, setLoadingOwners] = useState(true);

  useEffect(() => {
    instance
      .get("/onboard/business")
      .then((response) => {
        setBusinessData(response.data);
      })
      .then(() => {})
      .catch((error) => {})
      .finally(() => {
        setLoadingBusiness(false);
      });

    instance
      .get("/onboard/owners")
      .then((response) => {
        setOwners(response.data);
      })
      .catch((error) => {
        toast.error(error.response);
      })
      .finally(() => {
        setLoadingOwners(false);
      });
  }, []);

  const handleSubmit = () => {
    instance
      .post("/onboard/submit")
      .then((response) => {
        handleNext();
      })
      .catch((error) => {
        setErrorIndex(2);
        toast.error(error.message);
      });
  };

  return (
    <>
      <BackDrop open={loadingBusiness || loadingOwners} />

      {(!loadingBusiness || !loadingOwners) && (
        <>
          <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
            Details to review
          </Typography>
          <Divider />
          <Grid container p={3}>
            <BusinessDetails businessData={businessData} />
            <OwnersList owners={owners} />
          </Grid>

          <Grid item xs={12} mt={2.5}>
            <Stack direction="row" justifyContent="space-between">
              <Button
                variant="outlined"
                onClick={handleBack}
                sx={{ my: 3, ml: 1 }}
                size="small"
                startIcon={<ChevronLeftIcon />}
              >
                Back
              </Button>
              <Button
                variant="contained"
                sx={{ my: 3, ml: 1 }}
                type="submit"
                onClick={() => handleSubmit()}
                size="small"
                endIcon={<CheckIcon />}
                onSubmit={handleSubmit}
              >
                Submit
              </Button>
            </Stack>
          </Grid>
        </>
      )}
    </>
  );
}

export default Review;
